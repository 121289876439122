import styled from "styled-components";

type AspectRatioSizerProps = {
  aspect: number;
};
const AspectRatioSizer = styled.div.attrs<AspectRatioSizerProps>(
  ({ aspect }) => ({
    style: {
      paddingBottom: `${100 / aspect}%`,
    },
  })
)<AspectRatioSizerProps>`
  position: relative;
  width: 100%;
  height: 0;
`;

export default AspectRatioSizer;
