import Modal, { ModalProps } from "components/Modal";
import Typography from "components/Typography";
import Input from "components/Input";
import Button from "components/Button";
import { useEffect, useRef, useState } from "react";
import { IconDanger, IconInfo, IconSuccess, IconWarning } from "assets/icons";

type TextModalProps = ModalProps & {
  value?: string;
  textLength?: number;
  onComplete?(value: string): void;
};

const TextModal = (props: TextModalProps) => {
  const { onClose, visible, value, onComplete, textLength = 8 } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [length, setLength] = useState(0);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (onComplete) {
      onComplete(inputRef?.current?.value || "");
    }
  };
  useEffect(() => {
    if (visible && inputRef.current) {
      inputRef.current.value = value || "";
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [value, visible]);
  useEffect(() => {
    if (visible) {
      const element = inputRef.current;
      const handleChange = () => {
        setLength(element?.value?.length || 0);
      };
      if (element) {
        element.addEventListener("keydown", handleChange);
        element.addEventListener("keyup", handleChange);
        element.addEventListener("change", handleChange);
        handleChange();

        return () => {
          element.removeEventListener("keydown", handleChange);
          element.removeEventListener("keyup", handleChange);
          element.removeEventListener("change", handleChange);
        };
      }
    }
    return () => {};
  }, [visible]);
  return (
    <Modal title="텍스트" visible={visible} onClose={onClose} fitContent>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          width: "100vw",
          maxWidth: 356,
          margin: "0 auto",
        }}
        onSubmit={handleSubmit}
      >
        <div style={{ padding: 16 }}>
          {length <= 0 && (
            <Typography type="body2" center block>
              <IconInfo width={12} height={12} />
              <span>&nbsp; 이목을 집중시킬 텍스트로 입력하면 좋아요</span>
            </Typography>
          )}
          {length > 0 && length <= textLength && (
            <Typography type="body2" center block>
              <IconSuccess width={12} height={12} />
              <span>&nbsp; 템플릿에 잘 어울려요</span>
            </Typography>
          )}
          {length > textLength && length <= 50 && (
            <Typography type="body2" center block>
              <IconWarning width={12} height={12} />
              <span>&nbsp; 템플릿에 어울리지 않을 수 있어요</span>
            </Typography>
          )}
          {length > 50 && (
            <Typography type="body2" center block>
              <IconDanger width={12} height={12} />
              <span>&nbsp; 템플릿에 어울리지 않아요</span>
            </Typography>
          )}
        </div>
        <div>
          <Input
            block
            inputSize="small"
            defaultValue={value}
            ref={inputRef}
            placeholder="텍스트를 입력해주세요"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>&nbsp;</div>
          <div>
            <Typography type="caption1">
              {length}/{textLength}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 8,
          }}
        >
          <div>
            <Button link size="small" type="button" onClick={onClose}>
              취소
            </Button>
          </div>
          <div>
            <Button primary size="small" type="submit">
              저장하기
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TextModal;
