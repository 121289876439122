import Typography from "components/Typography";
import { useMemo } from "react";
import styled, { useTheme, css } from "styled-components";
import { Source, Source2 } from "types/template";
import Area from "./ScreenArea";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.text.primary};
  padding: 44px 0;
  justify-content: center;
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      padding: 0;
    `)}
`;

const Screen = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  max-width: 746px;
`;

const Info = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 13px 10px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
  }
`;

type SceneProps = {
  scene?: Source2 & {
    source: (Source & { isLoading?: boolean })[];
  };
  sources?: string[];
  onSourceClick?(index: number): void;
};

const Scene = (props: SceneProps) => {
  const theme = useTheme();
  const { scene, sources: data, onSourceClick } = props;
  const sources = useMemo(() => {
    return (scene?.source || [])
      .map((item, index) => {
        return { ...item, index };
      })
      .sort(({ sourceZindex: a = -1 }, { sourceZindex: b = -1 }) => {
        return a < b ? -1 : 1;
      });
    // .filter(({ sourceType }) =>
    //   ["I", "V", "T"].includes(`${sourceType}`.toUpperCase())
    // );
  }, [scene]);

  return (
    <>
      <Wrapper>
        <Screen>
          <svg viewBox="0 0 1280 720">
            <image href={scene?.sceneImage} width="100%" height="100%" />
            {sources?.map((source, index) => {
              const {
                sourceArea,
                sourceType,
                sourceWidth,
                sourceHeight,
                iconArea,
                thumbnailArea,
                sourceTextLength,
              } = source;
              return (
                ["I", "V", "T"].includes(`${sourceType}`.toUpperCase()) && (
                  <Area
                    isLoading={(source as any).isLoading}
                    key={JSON.stringify(source)}
                    points={sourceArea}
                    type={sourceType}
                    data={data?.[index]}
                    size={{
                      width: sourceWidth,
                      height: sourceHeight,
                    }}
                    iconPath={{ x: iconArea?.[0], y: iconArea?.[1] }}
                    thumbnailPath={{
                      x: thumbnailArea?.[0],
                      y: thumbnailArea?.[1],
                    }}
                    onClick={() => onSourceClick && onSourceClick(index)}
                    textLength={sourceTextLength?.[0] || 20}
                  />
                )
              );
            })}
          </svg>
        </Screen>
      </Wrapper>
      <Info>
        <Typography
          type="body2"
          style={{ color: theme.colors.text.primary, marginRight: 24 }}
        >
          씬이미지 구성요소
        </Typography>
        {/* <IconContentPhoto />
        <Typography
          type="body2"
          style={{ color: theme.colors.text.secondary, marginRight: 8 }}
        >
          0/1
        </Typography>
        <IconContentVideo />
        <Typography
          type="body2"
          style={{ color: theme.colors.text.secondary, marginRight: 8 }}
        >
          0/1
        </Typography>
        <IconContentText /> */}
        <Typography
          type="body2"
          style={{ color: theme.colors.text.secondary, marginRight: 8 }}
        >
          {data?.filter((item) => !!item).length || 0}/{sources?.length}
        </Typography>
      </Info>
    </>
  );
};

export default Scene;
