import styled, { css } from "styled-components";

import container from "components/Container";
import Button from "components/Button";
import Typography from "components/Typography";
// import { IconEdit } from "assets/icons";
import Tooltip from "components/Tooltip";
import useBreakpoint from "hooks/useBreakpoint";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const Container = styled(container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      justify-content: center;
      text-align: center;

      & > div:first-child {
        margin: 0 auto;
      }
    `)}
  & > div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

// const BtnEdit = styled(Button)`
//   padding: 0;
// `;

type HeaderProps = {
  title?: string;
  workProgress?: number;
  renderProgress?: number;
  projectStatus?: number;
  onCompleteClick?(): void;
  onGoBackClick?(): void;
};

const Header = (props: HeaderProps) => {
  const {
    title,
    workProgress,
    renderProgress,
    projectStatus: status = 0,
    onCompleteClick,
    onGoBackClick,
  } = props;
  const { isBreakpoint } = useBreakpoint();
  return (
    <Wrapper>
      <div
        style={{
          maxWidth: 490,
          width: "20%",
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10,
        }}
      >
        <Button link onClick={onGoBackClick}>
          ＜{!isBreakpoint("medium") && "나의 프로젝트"}
        </Button>
      </div>
      <div>
        <Container>
          <div>
            <Typography bold>{title}&nbsp;</Typography>
            {/* <BtnEdit link>
              <IconEdit />
            </BtnEdit> */}
          </div>
          <div>
            {workProgress && workProgress >= 100 ? (
              <Tooltip
                position={isBreakpoint("small") ? "left" : "bottom"}
                title={`프로젝트 편집이 모두 끝났다면 
                버튼을 눌러주세요!`}
              >
                <Button
                  size={isBreakpoint("small") ? "small" : "default"}
                  primary
                  disabled={status === 1 || status === 2}
                  onClick={() => onCompleteClick && onCompleteClick()}
                >
                  {status > 0 && status < 3 && `렌더링 중(${renderProgress}%)`}
                  {(status === 0 || status >= 3) && "완료하기"}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                position={isBreakpoint("small") ? "left" : "bottom"}
                title="자동으로 저장되고 있습니다."
              >
                <Button
                  size={isBreakpoint("small") ? "small" : "default"}
                  secondary
                >
                  임시 저장하기
                </Button>
              </Tooltip>
            )}
            &nbsp;
          </div>
        </Container>
      </div>
      <div />
    </Wrapper>
  );
};

export default Header;
