import styled, { css } from "styled-components";
import Button from "components/Button";
import { useEffect, useRef, useState } from "react";
import Typography from "components/Typography";

const visibleCss = css`
  opacity: 1;
  z-index: 50001;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      transform: translate(-50%, -50%) scale(1);
    `)}

  ${({ theme }) =>
    theme.breakpoints.large(css`
      transform: translate(0%, 0%);
    `)}
`;
const invisibleCss = css`
  opacity: 0;
  z-index: -1;
  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      transform: translate(-50%, -60%) scale(1.1);
    `)}

  ${({ theme }) =>
    theme.breakpoints.large(css`
      transform: translate(0%, 100%);
    `)}
`;

const Background = styled.div<{ visible?: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  ${({ visible }) =>
    visible
      ? css`
          z-index: 50000;
          display: block;
        `
      : css`
          display: none;
        `};
  transform: unset;
`;

const Wrapper = styled.div<{ visible?: boolean; fitContent?: boolean }>`
  display: inline-block;
  width: ${({ fitContent }) => (fitContent ? "auto" : "90%")};
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease-in-out;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      max-width: 720px;
      max-height: 90vh;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `)}

  ${({ theme }) =>
    theme.breakpoints.large(css`
      width: 100% !important;
      max-width: 100%;
      max-height: 90vh;
      position: fixed;
      top: unset;
      bottom: 0;
      left: 0;
    `)}

  ${({ visible }) => (visible ? visibleCss : invisibleCss)}
`;

const TitleBar = styled.div`
  width: 100%;
  height: 52px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border};
  user-select: none;
  & > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const Content = styled.div<{ fitContent: boolean }>`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  min-height: ${({ fitContent }) => (fitContent ? "unset" : "480px")};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type ModalProps = {
  closable?: boolean;
  visible?: boolean;
  title?: string | React.ReactNode;
  backButton?: string | React.ReactNode;
  onClose?(): void;
  onGoBack?(): void;
  style?: React.CSSProperties;
  fitContent?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  children,
  backButton,
  title,
  closable = true,
  visible,
  style,
  onGoBack,
  onClose,
  fitContent = false,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          event.key === "Escape" &&
          closable &&
          onClose &&
          document.activeElement &&
          (Array.from(
            wrapperRef?.current?.querySelectorAll("*") || []
          ).includes(document.activeElement) ||
            wrapperRef?.current === document.activeElement)
        ) {
          onClose();
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      wrapperRef?.current?.focus();

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
    return () => {};
  }, [closable, onClose, visible]);
  return (
    <>
      <Background visible={!!visible} onClick={onClose} />
      <Wrapper
        ref={wrapperRef}
        visible={!!visible}
        fitContent={fitContent}
        tabIndex={0}
      >
        <TitleBar>
          <div
            style={{
              left: 0,
            }}
          >
            {backButton && (
              <Button link onClick={onGoBack}>
                {backButton}
              </Button>
            )}
          </div>
          <div style={{ width: "100%", zIndex: 0, textAlign: "center" }}>
            {typeof title === "string" ? (
              <Typography bold type="body1">
                {title}
              </Typography>
            ) : (
              title
            )}
          </div>
          <div
            style={{
              right: 0,
            }}
          >
            {closable && (
              <Button link onClick={onClose}>
                X
              </Button>
            )}
          </div>
        </TitleBar>
        <Content style={style} fitContent={fitContent}>
          {children}
        </Content>
      </Wrapper>
    </>
  );
};

export const useModal = () => {
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  const toggle = () => setVisible((current) => !current);

  return { visible, open, close, toggle };
};

export default Modal;
