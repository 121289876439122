import styled, { css } from "styled-components";

const defaultCss = css`
  font-size: 1em;
  font-family: ${({ theme }) => theme.fonts.default};
`;

const body1Css = defaultCss;

const body2Css = css`
  font-size: 0.85em;
  font-family: ${({ theme }) => theme.fonts.default};
`;

const title1Css = css`
  font-size: 1.5em;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const title2Css = css`
  font-size: 1.3em;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const headlineCss = css`
  font-size: 1.75em;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const caption1Css = css`
  font-size: 0.75em;
  font-family: ${({ theme }) => theme.fonts.default};
`;

const caption2Css = css`
  font-size: 0.65em;
  font-family: ${({ theme }) => theme.fonts.default};
`;

const boldCss = css`
  font-weight: 700;
`;

const blockCss = css`
  display: flex;
`;

const centerCss = css`
  text-align: center;
  justify-content: center;
`;

type TypographyProps = {
  type?:
    | "default"
    | "headline"
    | "title1"
    | "title2"
    | "body1"
    | "body2"
    | "caption1"
    | "caption2";
  bold?: boolean;
  block?: boolean;
  center?: boolean;
};

const Typography = styled.span<TypographyProps>`
  display: inline-flex;
  vertical-align: middle;
  line-height: 1;
  justify-content: flex-start;
  align-items: center;

  ${({ type }) => {
    switch (type) {
      case "headline":
        return headlineCss;
      case "title1":
        return title1Css;
      case "title2":
        return title2Css;
      case "body1":
        return body1Css;
      case "body2":
        return body2Css;
      case "caption1":
        return caption1Css;
      case "caption2":
        return caption2Css;
      default:
        return defaultCss;
    }
  }}

  ${({ bold }) => bold && boldCss}

  ${({ block }) => block && blockCss}

  ${({ center }) => center && centerCss}
`;

export default Typography;
