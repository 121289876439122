import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import Button from "components/Button";

const Wrapper = styled.div<DrawerProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  ${({ position, visible }) => {
    switch (position) {
      case "top":
        return css`
          left: 0;
          top: 0;
          max-height: 364px;
          /* transform: ${visible ? "translateY(0)" : "translateY(-100%)"}; */
        `;
      case "right":
        return css`
          right: 0;
          top: 0;
          max-width: 364px;
          /* transform: ${visible ? "translateX(0)" : "translateX(100%)"}; */
        `;
      case "bottom":
        return css`
          left: 0;
          bottom: 0;
          max-height: 364px;
          /* transform: ${visible ? "translateY(0)" : "translateY(100%)"}; */
        `;
      case "left":
      default:
        return css`
          left: 0;
          top: 0;
          max-width: 364px;
          /* transform: ${visible ? "translateX(0)" : "translateX(-100%)"}; */
        `;
    }
  }}
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  transition: transform 0.4s cubic-bezier(0, 1, 0, 1);

  z-index: 300;
  overflow-y: auto;

  ${({ isChildDrawerOpen }) =>
    isChildDrawerOpen &&
    css`
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: hidden;
    `}

  &.drawer--has-child {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      max-height: unset;
      min-height: unset;
    `)}
`;

const Title = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.border};
  user-select: none;
  font-weight: 700;
`;

const Content = styled.div`
  width: 100%;
  .drawer--has-child > & {
    height: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  position: relative;
  flex: 1;
`;

const Outside = styled.div<DrawerProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 250;
  background-color: transparent;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export type DrawerProps = {
  title?: React.ReactNode | string;
  closable?: boolean;
  position?: "right" | "left" | "top" | "bottom";
  visible?: boolean;
  onClose?(): void;
  isChildDrawerOpen?: boolean;
  backButton?: string | React.ReactNode;
  onGoBack?(): void;
};

const Drawer: React.FC<DrawerProps> = ({
  children,
  title,
  position = "left",
  visible,
  onClose,
  isChildDrawerOpen,
  backButton,
  onGoBack,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.document.querySelectorAll(".drawer--visible").forEach((el) => {
      if (el) {
        if (el.querySelectorAll(".drawer--visible").length > 0) {
          el.classList.add("drawer--has-child");
          return;
        }
        el.classList.remove("drawer--has-child");
      }
    });
  }, [visible]);
  return (
    <>
      <Outside visible={visible} onClick={() => onClose && onClose()} />
      <CSSTransition
        in={visible}
        timeout={300}
        unmountOnExit
        classNames={`drawer-transition-${position}`}
      >
        <Wrapper
          ref={wrapperRef}
          className={visible ? "drawer--visible" : "drawer--invisible"}
          position={position}
          visible={visible}
          isChildDrawerOpen={isChildDrawerOpen}
        >
          {title && (
            <Title>
              {backButton && (
                <div
                  style={{
                    left: 0,
                    position: "absolute",
                  }}
                >
                  <Button link onClick={onGoBack}>
                    {backButton}
                  </Button>
                </div>
              )}
              {title}
            </Title>
          )}
          <Content>{children}</Content>
        </Wrapper>
      </CSSTransition>
    </>
  );
};

export default Drawer;
