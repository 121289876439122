import { authenticate as Pixabay } from "pixabay-api";
import Axios from "axios";
import queryString from "querystring";

const { create } = Axios;

const pixabay = Pixabay(process.env.REACT_APP_PIXABAY_KEY || "");

const pexelsAxios = create({
  baseURL: "https://api.pexels.com",
  withCredentials: false,
});

pexelsAxios.defaults.headers = {
  Authorization: process.env.REACT_APP_PEXELS_KEY,
};

function api() {
  const searchVideos = async (
    data = {
      query: "",
      page: 1,
      limit: 20,
      orientation: "",
      from: "pixabay",
      minDuration: 0,
    }
  ) => {
    const { query, from } = data;
    let { page, limit, orientation, minDuration } = data;
    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    if (!minDuration || Number.isNaN(minDuration)) {
      minDuration = 0;
    }

    let result: any = {};
    const resultToReturn = {
      total: 0,
      limit,
      page,
      totalPage: 0,
      data: [],
    };

    result = await pexelsAxios({
      url:
        (query ? "/videos/search?" : "/videos/popular?") +
        queryString.stringify({ per_page: limit, query, page }),
      method: "get",
    });
    if (Array.isArray(result?.data?.videos)) {
      resultToReturn.total = result.data.total_results;
      resultToReturn.totalPage = Math.ceil(result.data.total / limit);
      resultToReturn.data = result.data.videos
        .filter(({ duration }: any) => {
          return parseInt(duration, 10) >= minDuration;
        })
        .map(({ image: thumbnail, video_files: videoFiles, url }: any) => {
          const video = (videoFiles || []).find(
            (item: { width: string; height: string }) => {
              return (
                parseInt(item?.width, 10) >= 1920 ||
                parseInt(item?.height, 10) >= 1080
              );
            }
          );
          return {
            thumbnail,
            // video: videoFiles?.[0]?.link,
            video: video?.link,
            width: video?.width,
            height: video?.height,
            from,
            link: url || "https://pexels.com",
          };
        });
    }
    return resultToReturn;
  };

  const searchPhotos = async (
    data = {
      query: "",
      page: 1,
      limit: 20,
      orientation: "landscape|portrait",
      from: "unsplash",
    }
  ) => {
    const { query, from } = data;
    let { page, limit, orientation } = data;
    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    if (["landscape", "portrait"].indexOf(orientation) < 0) {
      orientation = "";
    }

    let result: any = {};
    const resultToReturn = {
      total: 0,
      limit,
      page,
      totalPage: 0,
      data: [],
    };

    switch (from) {
      case "pexels":
        result = await pexelsAxios({
          url:
            (query ? "/v1/search?" : "/v1/curated?") +
            queryString.stringify({ per_page: limit, query, page }),
          method: "get",
        });
        if (Array.isArray(result?.data?.photos)) {
          resultToReturn.total = result.data.total_results;
          resultToReturn.totalPage = Math.ceil(result.data.total / limit);
          resultToReturn.data = result.data.photos.map(
            ({
              src,
              width,
              height,
              url,
              photographer,
              photographer_url: photographerUrl,
            }: any) => ({
              thumbnail: src.tiny,
              // image: src[orientation] || src.large,
              image: src.original,
              width,
              height,
              from,
              link: url || "https://pexels.com",
              author: { name: photographer, link: photographerUrl },
            })
          );
        }
        break;
      case "pixabay":
        if (orientation === "landscape") {
          orientation = "horizontal";
        } else if (orientation === "portrait") {
          orientation = "vertical";
        } else {
          orientation = "all";
        }
        if (query) {
          result = await pixabay.searchImages(query, {
            per_page: limit,
            page,
            // orientation,
          });
          resultToReturn.total = result.total;
          resultToReturn.totalPage = Math.ceil(result.total / limit);
          resultToReturn.data = result.hits.map(
            ({
              previewURL: thumbnail,
              fullHDURL: image,
              imageWidth: width,
              imageHeight: height,
              pageURL,
              user,
              userImageURL,
            }: any) => ({
              thumbnail,
              image,
              width,
              height,
              from,
              link: pageURL || "https://pixabay.com",
              author: {
                name: user,
                image: userImageURL,
                link: pageURL || "https://pixabay.com",
              },
            })
          );
        }
        break;

      case "unsplash":
      default:
        if (query) {
          result = (
            await Axios.get(
              `${process.env.REACT_APP_API_URL}/design/unspalsh`,
              { params: { search: query, limit, page } }
            )
          ).data?.result;

          resultToReturn.total = result.total;
          resultToReturn.totalPage = 10;
          resultToReturn.data = result.results.map(
            ({ urls, width, height, links, user }: any) => ({
              thumbnail: urls?.thumb || "",
              image: urls?.full || "",
              width,
              height,
              from,
              link: `${
                links?.html || "https://unsplash.com"
              }?utm_source=vplate.io&utm_medium=referral`,
              author: user
                ? {
                    name: user?.name,
                    link: `${
                      user?.links?.html || "https://unsplash.com"
                    }?utm_source=vplate.io&utm_medium=referral`,
                    // eslint-disable-next-line camelcase
                    image: user?.profile_image?.small,
                  }
                : undefined,
            })
          );
        } else {
          result = (
            await Axios.get(
              `${process.env.REACT_APP_API_URL}/design/unspalsh`,
              { params: { limit: 20 } }
            )
          ).data?.result;
          resultToReturn.total = result.total;
          resultToReturn.totalPage = 1;
          resultToReturn.data = result?.results.map(
            ({ urls, width, height, links, user }: any) => ({
              thumbnail: urls?.thumb || "",
              image: urls?.regular || "",
              width,
              height,
              from,
              link: `${
                links?.html || "https://unsplash.com"
              }?utm_source=vplate.io&utm_medium=referral`,
              author: user
                ? {
                    name: user?.name,
                    link: `${
                      user?.links?.html || "https://unsplash.com"
                    }?utm_source=vplate.io&utm_medium=referral`,
                    // eslint-disable-next-line camelcase
                    image: user?.profile_image?.small,
                  }
                : undefined,
            })
          );
        }
        break;
    }
    return resultToReturn;
  };
  return {
    search: searchPhotos,
    searchPhotos,
    searchVideos,
  };
}

export default api;
