import Modal, { ModalProps } from "components/Modal";
import Typography from "components/Typography";
import Input from "components/Input";
import Button from "components/Button";
import Tabs from "components/Tabs";
import { useCallback, useEffect, useRef, useState } from "react";
import { images } from "api";
import styled, { css, useTheme } from "styled-components";
import { iconContentPhoto } from "assets/icons";
import AspectRatioSizer from "components/AspectRatioSizer";
import resizeImage from "utils/resizeImage";
import CropImage from "./CropImage";

type PhotoModalProps = ModalProps & {
  value?: string;
  onComplete?(dataUrl: string): void;
  aspect?: number;
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      flex-direction: column-reverse;
    `)}
`;

const Content = styled.div`
  flex: 2;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 48px;
`;

const PreviewPanel = styled.div`
  flex: 1;
  max-width: 256px;
  text-align: center;
  position: relative;
  ${({ theme }) =>
    theme.breakpoints.small(css`
      max-width: unset;
      width: 100%;
    `)}
`;

const SearchForm = styled.form`
  width: 100%;
  height: 80vh;
  max-height: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ImageList = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
`;
type ImageListItemProps = { src?: string; selected?: boolean };
const ImageListItem = styled.button.attrs<ImageListItemProps>(({ src }) => ({
  style: {
    backgroundImage: `url('${src}')`,
  },
}))<ImageListItemProps>`
  width: 100%;
  height: auto;
  max-width: 86px;
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: border 0.2s ease-in-out;
  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `};
`;

ImageListItem.defaultProps = { type: "button" };

const Footer = styled.div`
  display: inline-block;
  max-width: 256px;
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 16px;
`;

const PhotoModal = (props: PhotoModalProps) => {
  const { onClose, visible, value, onComplete, aspect = 1 } = props;
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentStep, setCurrentStep] = useState(0);

  const [imageList, setImageList] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const fetchImages = useCallback(
    async (query?: string) => {
      if (isSearchLoading) {
        return;
      }
      setIsSearchLoading(true);
      setImageList([]);
      const res = await images().searchPhotos({
        from: query ? "pixabay" : "pexels",
        query: query || "",
        limit: 100,
        page: 1,
        orientation: "horizontal",
      });
      console.log(res);
      if (res?.data) {
        setImageList(res.data);
      }
      setIsSearchLoading(false);
    },
    [isSearchLoading]
  );

  const handleSearch: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement);
      const query = formData.get("query") as string;
      fetchImages(query);
    },
    [fetchImages]
  );

  useEffect(() => {
    if (visible && inputRef.current) {
      inputRef.current.value = "";
    }
    if (visible && !isSearchLoading && !imageList.length) {
      fetchImages();
    }
  }, [fetchImages, imageList, isSearchLoading, value, visible]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0);
        setSelectedImageUrl("");
      }, 500);
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [visible]);

  const handleInputFileChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        if (reader?.result) {
          const res = await resizeImage(reader.result as string, 1920);
          if (res) {
            setSelectedImageUrl(res);
          }
        }
      };
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = "";
  }, []);

  return (
    <Modal title="사진 불러오기" visible={visible} onClose={onClose}>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleInputFileChange}
        style={{
          width: 0,
          height: 0,
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: -1,
          opacity: 0,
        }}
      />
      {currentStep === 0 && (
        <Wrapper>
          <Content>
            <Tabs
              tabs={[
                {
                  title: "파일",
                  onClick: () => {
                    inputRef.current?.click();
                  },
                },
                {
                  title: "무료 사진",
                  children: (
                    <SearchForm onSubmit={handleSearch}>
                      <Input
                        name="query"
                        placeholder="검색하기"
                        block
                        type="search"
                        inputMode="search"
                        autoComplete="off"
                        style={{ marginBottom: 16 }}
                      />
                      <ImageList style={{ flex: 1, overflowY: "auto" }}>
                        {isSearchLoading && "불러오는 중..."}
                        {!isSearchLoading &&
                          imageList &&
                          imageList.map((image: any) => {
                            return (
                              <ImageListItem
                                key={image.thumbnail || ""}
                                src={image.thumbnail}
                                selected={image?.image === selectedImageUrl}
                                onClick={() => {
                                  setSelectedImageUrl(image?.image);
                                }}
                              >
                                <AspectRatioSizer aspect={16 / 9} />
                              </ImageListItem>
                            );
                          })}
                      </ImageList>
                    </SearchForm>
                  ),
                },
                { title: "클라우드", disabled: true },
              ]}
              selectedTabIndex={1}
            />
          </Content>
          <PreviewPanel>
            <div style={{ padding: 16 }}>
              <Typography bold block center type="body2">
                미리보기
              </Typography>
            </div>
            <div style={{ paddingTop: 32, paddingBottom: 32 }}>
              {!selectedImageUrl && (
                <>
                  <img
                    src={iconContentPhoto}
                    alt="preview"
                    style={{ width: 56, marginBottom: 16 }}
                  />
                  <Typography
                    block
                    center
                    type="body2"
                    style={{ color: theme.colors.text.secondary }}
                  >
                    왼쪽에 나열된 무료 사진 중<br /> 마음에드는 것을
                    선택해주세요
                  </Typography>
                </>
              )}

              {selectedImageUrl && (
                <img
                  src={selectedImageUrl}
                  alt="preview"
                  style={{
                    width: "100%",
                    maxHeight: 360,
                    objectFit: "contain",
                    objectPosition: "center center",
                  }}
                />
              )}
            </div>
          </PreviewPanel>
          <Footer>
            <Button
              primary
              size="small"
              disabled={!selectedImageUrl}
              onClick={() => setCurrentStep(1)}
            >
              불러오기
            </Button>
          </Footer>
        </Wrapper>
      )}
      {currentStep === 1 && (
        <CropImage
          image={selectedImageUrl}
          aspect={aspect}
          restrictPosition
          onCrop={async (dataUrl) => onComplete && onComplete(dataUrl)}
        />
      )}
    </Modal>
  );
};

export default PhotoModal;
