import styled, { css, useTheme } from "styled-components";
import { Popover } from "react-tiny-popover";
import Typography from "components/Typography";
import Button from "components/Button";
import { useCallback, useEffect, useRef, useState } from "react";
import { service, files } from "api/index";
import { formatSeconds } from "utils";
import Drawer from "components/Drawer";
import Tooltip from "components/Tooltip";
import Range from "components/Range";
import WaveformGenerator from "utils/generateWaveform";
import axios from "axios";
import AudioPlayer from "components/Audio";
import { IconFilter } from "assets/icons";
import { useModal } from "components/Modal";
import { Music } from "api/service";
import useBreakpoint from "hooks/useBreakpoint";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      max-width: 360px;
    `)}
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      max-width: unset;
    `)}
`;

const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  gap: 8px;
`;

type MusicPanelProps = {
  duration?: number;
  defaultMusicUrl?: string;
  currentMusicUrl?: string | null;
  onChange?(newBgmSrc: string | null, newBgm: Music | null): void;
  visible?: boolean;
};

const MUSIC_MOODS = [
  "전체",
  "밝은",
  "화난",
  "진지한",
  "어두운",
  "행복한",
  "펑키한",
  "편안한",
  "이상한",
  "낭만적인",
  "몽환적인",
];

const MUSIC_GENRES = [
  "전체",
  "팝",
  "힙합",
  "재즈 및 블루스",
  "R&B",
  "클래식",
  "댄스 및 일렉트로닉",
  "컨트리 및 포크",
  "아동",
  "펑크",
  "잔잔한 음악",
];

const MusicPanel = (props: MusicPanelProps) => {
  const theme = useTheme();
  const { isBreakpoint } = useBreakpoint();
  const {
    duration,
    defaultMusicUrl: initialDefaultMusicUrl,
    currentMusicUrl: initialCurrentMusicUrl,
    onChange = () => {},
    visible,
  } = props;
  const [defaultMusicUrl, setDefaultMusicUrl] = useState(
    initialDefaultMusicUrl
  );
  const [currentMusicUrl, setCurrentMusicUrl] = useState(
    initialCurrentMusicUrl
  );
  const [musicList, setMusicList] = useState<Music[]>([]);
  const [selectedMusic, setSelectedMusic] = useState<Music>();
  const [selectedMusicWaveform, setSelectedMusicWaveform] = useState<string>();
  const [drawerPage, setDrawerPage] = useState<"preview" | "trim">("preview");
  const [startTime, setStartTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const filterPopover = useModal();
  const [selectedMoods, setSelectedMoods] = useState<number[]>([0]);
  const [selectedGenres, setSelectedGenres] = useState<number[]>([0]);

  useEffect(() => {
    if (!selectedMoods.length) {
      setSelectedMoods([0]);
    }
    if (selectedMoods.length > 1 && selectedMoods.includes(0)) {
      setSelectedMoods((current) => current.filter((item) => item !== 0));
    }
  }, [selectedMoods]);

  useEffect(() => {
    if (!selectedGenres.length) {
      setSelectedGenres([0]);
    }
    if (selectedGenres.length > 1 && selectedGenres.includes(0)) {
      setSelectedGenres((current) => current.filter((item) => item !== 0));
    }
  }, [selectedGenres]);

  const defaultMusicRef = useRef<HTMLAudioElement>(null);
  const currentMusicRef = useRef<HTMLAudioElement>(null);

  const handleClearClick = useCallback(() => {
    onChange(null, null);
  }, [onChange]);

  const handleComplete = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (selectedMusic && duration) {
      const result = await files().music.upload({
        musicUrl: selectedMusic?.musicUrl,
        trimAudioDuration: duration,
        trimAudioStart: startTime,
        fadeEffect: true,
      });
      if (result?.musicUrl) {
        setCurrentMusicUrl(result?.musicUrl);
        onChange(result?.musicUrl, selectedMusic);
        setSelectedMusic(undefined);
      } else {
        // throw Error();
      }
    }
    setIsLoading(false);
  }, [duration, isLoading, onChange, selectedMusic, startTime]);

  useEffect(() => {
    if (!selectedMusic) {
      setDrawerPage("preview");
    }
  }, [selectedMusic]);

  useEffect(() => {
    setDefaultMusicUrl(initialDefaultMusicUrl);
  }, [initialDefaultMusicUrl]);

  useEffect(() => {
    setCurrentMusicUrl(initialCurrentMusicUrl);
  }, [initialCurrentMusicUrl]);

  useEffect(() => {
    const fetchMusicList = async () => {
      const res = await service().music.list({ limit: 9999, page: 1 });
      if (Array.isArray(res?.result)) {
        setMusicList(res.result);
      }
    };
    fetchMusicList();
  }, []);

  useEffect(() => {
    const fetchWaveform = async () => {
      setSelectedMusicWaveform("");
      if (selectedMusic?.musicUrl) {
        const res = await axios.get<ArrayBuffer>(selectedMusic?.musicUrl, {
          responseType: "arraybuffer",
          headers: { "Access-Control-Allow-Origin": "*" },
        });
        if (res?.data) {
          const audioBuffer = res.data;
          const waveformGenerator = new WaveformGenerator(audioBuffer);
          const waveformSettings = {
            barWidth: 1,
            barGap: 0,
            waveformColor: theme.colors.text.primary,
            barAlign: "center",
            waveformHeight: 48,
            waveformWidth: 1920,
            drawMode: "png",
          };
          const waveformResult = await waveformGenerator.getWaveform({
            ...waveformSettings,
          });
          if (waveformResult) {
            setSelectedMusicWaveform(waveformResult);
          }
        }
      }
    };
    fetchWaveform();
  }, [selectedMusic, theme]);

  useEffect(() => {
    if (!visible) {
      defaultMusicRef.current?.pause();
      currentMusicRef.current?.pause();
    }
  }, [visible]);

  return (
    <Wrapper>
      <Container>
        <div style={{ borderBottom: `1px solid ${theme.colors.border}` }}>
          <Row>
            <div>
              <Typography bold type="body2">
                기존 배경음
              </Typography>
            </div>
          </Row>
          <Row style={{ borderBottom: `1px solid ${theme.colors.border}` }}>
            <div style={{ width: "100%" }}>
              <AudioPlayer
                controls
                src={defaultMusicUrl}
                ref={defaultMusicRef}
              />
            </div>
          </Row>
          <Row>
            <div>
              <Typography bold type="body2">
                변경 배경음
              </Typography>
            </div>
            <div>
              <Button link onClick={handleClearClick}>
                <Typography bold type="body2">
                  기존 배경음으로
                </Typography>
              </Button>
            </div>
          </Row>
          <Row>
            <div style={{ width: "100%" }}>
              {currentMusicUrl ? (
                <AudioPlayer
                  controls
                  src={currentMusicUrl}
                  ref={currentMusicRef}
                />
              ) : (
                <Typography block type="body2" center>
                  변경한 배경음이 없습니다.
                </Typography>
              )}
            </div>
          </Row>
        </div>
        <div>
          <Row>
            <div>
              <Typography bold type="body2">
                목록
              </Typography>
            </div>
            <div>
              <Popover
                content={() => (
                  <div>
                    <div
                      style={{
                        backgroundColor: theme.colors.border,
                        padding: 16,
                      }}
                    >
                      <Typography type="body1" center bold block>
                        필터
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "top",
                        borderBottom: `1px solid ${theme.colors.border}`,
                      }}
                    >
                      <div
                        style={{
                          borderRight: `1px solid ${theme.colors.border}`,
                          flex: 1,
                          padding: 16,
                        }}
                      >
                        <div style={{ padding: "0 16px" }}>
                          <Typography type="body2" bold>
                            분위기
                          </Typography>
                        </div>
                        <div
                          style={{
                            padding: 16,
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 4,
                          }}
                        >
                          {MUSIC_MOODS.map((item, index) => (
                            <Button
                              key={item}
                              size="small"
                              link={!selectedMoods.includes(index)}
                              primary={selectedMoods.includes(index)}
                              onClick={() => {
                                if (index === 0) {
                                  setSelectedMoods([0]);
                                  return;
                                }
                                setSelectedMoods((current) =>
                                  current.includes(index)
                                    ? current.filter((value) => value !== index)
                                    : [...current, index]
                                );
                              }}
                            >
                              <Typography type="body2">{item}</Typography>
                            </Button>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          padding: 16,
                        }}
                      >
                        <div style={{ padding: "0 16px" }}>
                          <Typography type="body2" bold>
                            장르
                          </Typography>
                        </div>
                        <div
                          style={{
                            padding: 16,
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {MUSIC_GENRES.map((item, index) => (
                            <Button
                              key={item}
                              size="small"
                              link={!selectedGenres.includes(index)}
                              primary={selectedGenres.includes(index)}
                              onClick={() => {
                                if (index === 0) {
                                  setSelectedGenres([0]);
                                  return;
                                }
                                setSelectedGenres((current) =>
                                  current.includes(index)
                                    ? current.filter((value) => value !== index)
                                    : [...current, index]
                                );
                              }}
                            >
                              <Typography type="body2">{item}</Typography>
                            </Button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                positions={["bottom", isBreakpoint("large") ? "right" : "left"]}
                padding={10}
                reposition={false}
                isOpen={filterPopover.visible}
                align={isBreakpoint("large") ? "end" : "start"}
                onClickOutside={() => filterPopover.close()}
                containerStyle={{
                  zIndex: "500",
                  backgroundColor: theme.colors.white,
                  width: "80vw",
                  maxHeight: "50vh",
                  overflowY: "auto",
                  maxWidth: "460px",
                }}
              >
                <Button link onClick={() => filterPopover.toggle()}>
                  <IconFilter height={14} />
                  &nbsp;
                  <Typography bold type="body2">
                    필터
                  </Typography>
                </Button>
              </Popover>
            </div>
          </Row>
          <Row>
            <div
              style={{
                width: "100%",
                wordBreak: "break-all",
                minHeight: "50vh",
              }}
            >
              {musicList
                .filter((music) => {
                  if (
                    !selectedMoods.includes(0) &&
                    !selectedMoods.includes(music.musicMood)
                  ) {
                    return false;
                  }
                  if (
                    !selectedGenres.includes(0) &&
                    !selectedGenres.includes(music.musicGenre)
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((item) => (
                  <div
                    key={item._id}
                    style={{ borderBottom: `1px solid ${theme.colors.border}` }}
                  >
                    <Button
                      link
                      block
                      style={{
                        fontWeight: 400,
                        color: theme.colors.text.primary,
                        textAlign: "justify",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => setSelectedMusic(item)}
                    >
                      <Tooltip position="top" title={item?.musicTitle}>
                        <div>♫ {item.musicTitle}</div>
                      </Tooltip>
                      <div>
                        <Typography type="caption1">
                          {formatSeconds(item.musicLength)}
                        </Typography>
                      </div>
                    </Button>
                  </div>
                ))}
            </div>
          </Row>
        </div>
      </Container>

      <Drawer
        position="bottom"
        visible={selectedMusic && drawerPage === "preview"}
        title="배경음 듣기"
        closable
        onClose={() => setSelectedMusic(undefined)}
      >
        <Row>
          <Button
            link
            block
            style={{
              fontWeight: 400,
              color: theme.colors.text.primary,
              textAlign: "justify",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{selectedMusic?.musicTitle}</div>
            <div>
              <Typography type="caption1">
                {formatSeconds(selectedMusic?.musicLength || 0)}
              </Typography>
            </div>
          </Button>
        </Row>
        <Row>
          <Typography block type="body2">
            {selectedMusic?.musicDescription}
          </Typography>
        </Row>
        <Row>
          {selectedMusic && drawerPage === "preview" && (
            <AudioPlayer controls src={selectedMusic?.musicUrl} />
          )}
        </Row>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            size="small"
            secondary
            onClick={() => setSelectedMusic(undefined)}
          >
            닫기
          </Button>
          <Button size="small" primary onClick={() => setDrawerPage("trim")}>
            구간 설정
          </Button>
        </Row>
      </Drawer>
      <Drawer
        position="bottom"
        visible={selectedMusic && drawerPage === "trim"}
        title="구간 설정"
        closable
        onClose={() => setSelectedMusic(undefined)}
      >
        <Row>
          <Button
            link
            block
            style={{
              fontWeight: 400,
              color: theme.colors.text.primary,
              textAlign: "justify",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{selectedMusic?.musicTitle}</div>
            <div>
              <Typography type="caption1">
                {formatSeconds(selectedMusic?.musicLength || 0)}
              </Typography>
            </div>
          </Button>
        </Row>
        <Row>
          {selectedMusic && (
            <Range
              background={selectedMusicWaveform}
              originalDuration={selectedMusic.musicLength}
              duration={duration}
              value={startTime}
              onChange={(value) => setStartTime(value)}
            />
          )}
        </Row>
        <Row>
          <Typography block type="body2" bold>
            설정한 구간 듣기
          </Typography>
        </Row>
        <Row>
          {selectedMusic && drawerPage === "trim" && (
            <AudioPlayer
              controls
              src={selectedMusic?.musicUrl}
              duration={duration}
              startTime={startTime}
            />
          )}
        </Row>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            size="small"
            secondary
            onClick={() => setSelectedMusic(undefined)}
          >
            닫기
          </Button>
          <Button
            size="small"
            primary
            onClick={handleComplete}
            disabled={isLoading}
          >
            완료
          </Button>
        </Row>
      </Drawer>
    </Wrapper>
  );
};

export default MusicPanel;
