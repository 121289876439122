import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const TabList = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  overflow-x: auto;
`;

const TabListItem = styled.button<{ selected?: boolean }>`
  width: auto;
  height: auto;
  position: relative;
  padding: 8px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.secondary};
  white-space: nowrap;

  transition: all 0.2s ease-in-out;
  cursor: pointer;
  line-height: 1;

  &:disabled {
    cursor: not-allowed;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0%;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: all 0.2s ease-in-out;
    transform: translateX(-50%);

    ${({ selected }) =>
      selected &&
      css`
        width: 100%;
      `}
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.colors.text.primary};
    `}
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px;
`;

type TabProps = {
  title: string;
  key?: string;
  disabled?: boolean;
  onClick?(): void;
  children?: JSX.Element;
};

type TabsProps = {
  tabs: TabProps[];
  selectedTabIndex?: number;
  onChange?: (index: number) => void;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  selectedTabIndex: selectedTabIndexFromProps,
  onChange,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    selectedTabIndexFromProps ?? 0
  );
  useEffect(() => {
    if (onChange) {
      onChange(selectedTabIndex);
    }
  }, [onChange, selectedTabIndex]);
  return (
    <Wrapper>
      <TabList>
        {tabs.map((tab, index) => {
          const { key, title, onClick, disabled } = tab;
          return (
            <TabListItem
              key={key || title}
              type="button"
              disabled={disabled}
              onClick={() => {
                setSelectedTabIndex(index);
                if (onClick) {
                  onClick();
                }
              }}
              selected={
                (selectedTabIndexFromProps ?? selectedTabIndex) === index
              }
            >
              {title}
            </TabListItem>
          );
        })}
      </TabList>
      <Content>
        {tabs?.[selectedTabIndexFromProps ?? selectedTabIndex]?.children}
      </Content>
    </Wrapper>
  );
};

export default Tabs;
