import React from "react";
import styled, { css } from "styled-components";
import Tooltip from "components/Tooltip";
import Drawer, { DrawerProps } from "components/Drawer";
import useBreakpoint from "hooks/useBreakpoint";
import Typography from "components/Typography";

type Icons = {
  default: string;
  hover?: string;
  active?: string;
  disabled?: string;
};

type Menu = {
  icons: Icons;
  title?: string;
  drawer?: DrawerProps & { children: JSX.Element };
} & Partial<React.HTMLProps<HTMLButtonElement>>;

type SidebarProps = {
  menu?: Menu[];
};

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 4px;
  padding: 4px;
  z-index: 400;
  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      width: auto;
      height: 100%;
      left: 0;
      top: 0;
      bottom: unset;
      flex-direction: column;
      border-right: 1px solid ${theme.colors.border};
    `)};
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      position: fixed;
      width: 100%;
      height: auto;
      left: 0;
      top: unset;
      bottom: 0;
      flex-direction: row;
      border-top: 1px solid ${theme.colors.border};
      justify-content: center;
    `)};

  ${({ theme }) =>
    theme.breakpoints.small(css`
      justify-content: space-around;
    `)};

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 150;
  }
`;

const DrawerWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  z-index: ${({ visible }) => (visible ? 100 : -1)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: ${({ visible }) => (visible ? "unset" : "all 0.4s step-end")};

  ${({ theme, visible }) =>
    theme.breakpoints.xxxLarge(css`
      width: 80vw;
      height: 100%;
      top: 0;
      left: ${visible ? "100%" : "-3000%"};
    `)}

  ${({ theme, visible }) =>
    theme.breakpoints.xLarge(css`
      width: 100vw;
      height: calc(100vh - 50px);
      height: calc(var(--vh, 1vh) * 100 - 50px);
      top: unset;
      left: 0;
      bottom: ${visible ? "100%" : "-3000%"};
    `)}
`;

const Button = styled.button<{ icons: Icons }>`
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("${({ icons }) => icons.default}");
  z-index: 160;

  &:hover {
    background-image: url("${({ icons }) => icons.hover || icons.default}");
    opacity: 0.8;
  }

  &:active {
    background-image: url("${({ icons }) => icons.active || icons.default}");
  }
`;

const Sidebar = ({ menu }: SidebarProps) => {
  const { isBreakpoint } = useBreakpoint();
  return (
    <Wrapper>
      {menu?.map(({ icons, title, key, drawer, onClick }) => (
        <React.Fragment key={key || JSON.stringify(icons)}>
          <Tooltip
            title={title}
            position={isBreakpoint("xLarge") ? "top" : "right"}
          >
            <Button type="button" icons={icons} onClick={onClick} />
          </Tooltip>
          {drawer && (
            <DrawerWrapper visible={!!drawer.visible}>
              <Drawer
                title={title}
                visible={drawer.visible}
                onClose={drawer.onClose}
                onGoBack={drawer.onClose}
                backButton={
                  isBreakpoint("xLarge") && <Typography>&lt; 이전</Typography>
                }
                closable={drawer.closable}
                position={isBreakpoint("xLarge") ? "bottom" : "left"}
              >
                {drawer.children}
              </Drawer>
            </DrawerWrapper>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Sidebar;
