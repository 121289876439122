import { useState, useEffect, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import { Range as ReactRange } from "react-range";

const HEIGHT = 48;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const Range: React.FC<{
  background?: string;
  originalDuration?: number;
  duration?: number;
  value?: number;
  onChange?(value: number): void;
  style?: React.CSSProperties;
}> = ({
  background,
  value: valueFromProps = 0,
  onChange: handleChange,
  originalDuration = 100,
  duration = 10,
  style,
}) => {
  const theme = useTheme();
  // const [audioBuffer, setAudioBuffer] = useState<ArrayBuffer>();
  // const [background, setBackground] = useState<string>();
  const [value, setValue] = useState(valueFromProps + duration / 2);

  // const generateWaveform = useCallback(async () => {
  //   if (audioBuffer) {
  //     const waveformGenerator = new WaveformGenerator(audioBuffer);
  //     const waveformSettings = {
  //       barWidth: 1,
  //       barGap: 0,
  //       waveformColor: theme.colors.text.primary,
  //       barAlign: "center",
  //       waveformHeight: HEIGHT,
  //       waveformWidth: 1920,
  //       drawMode: "png",
  //     };
  //     const waveformResult = await waveformGenerator.getWaveform({
  //       ...waveformSettings,
  //     });

  //     return waveformResult;
  //   }
  //   return "";
  // }, [audioBuffer, theme]);

  const handleSliderChange = useCallback(
    (newValue: number) => {
      if (newValue - duration / 2 <= 0) {
        setValue(duration / 2);
        return;
      }
      if (newValue + duration / 2 >= originalDuration) {
        setValue(originalDuration - duration / 2);
        return;
      }
      setValue(newValue);
    },
    [duration, originalDuration]
  );

  useEffect(() => {
    if (handleChange) {
      handleChange(value - duration / 2);
    }
  }, [duration, handleChange, value]);

  useEffect(() => {
    setValue(duration / 2);
  }, [duration]);
  
  return (
    <Wrapper style={style}>
      <ReactRange
        min={0}
        max={originalDuration}
        step={0.1}
        values={[value]}
        onChange={([newValue]) => {
          handleSliderChange(newValue);
        }}
        renderTrack={({ props, children }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style,
              height: HEIGHT,
              background: theme.colors.border,
              backgroundImage: `url('${background}')`,
              backgroundPosition: "50% 50%",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              width: "100%",
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style,
              width: `${(duration / originalDuration) * 100}%`,
              height: HEIGHT,
              border: `1px solid ${theme.colors.primary}`,
              background: `${theme.colors.primary}55`,
            }}
          />
        )}
      />
    </Wrapper>
  );
};

export default Range;
