import styled, { useTheme, css } from "styled-components";
import {
  IconContentDuration,
  IconContentPhoto,
  IconContentText,
  IconContentVideo,
} from "assets/icons";
import Typography from "components/Typography";
import useBreakpoint from "hooks/useBreakpoint";

const Wrapper = styled.div`
  height: auto;
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      gap: 24px;
    `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 8px;
    `)}
    
  ${({ theme }) =>
    theme.breakpoints.small(css`
      gap: 4px;
    `)}

  & > div {
    display: inline-block;

    & > svg {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
`;

type Numbers = {
  required?: number;
  current?: number;
};

const Count = (props: {
  duration?: number;
  text?: Numbers;
  video?: Numbers;
  photo?: Numbers;
}) => {
  const { duration = 0, text, video, photo } = props;
  const theme = useTheme();
  const { isBreakpoint } = useBreakpoint();
  return (
    <Wrapper>
      <div>
        <IconContentDuration />
        {!isBreakpoint("small") && (
          <Typography style={{ color: theme.colors.text.secondary }}>
            재생시간&nbsp;
          </Typography>
        )}
        <Typography bold>{duration}초</Typography>
      </div>
      <div>
        <IconContentPhoto />
        {!isBreakpoint("small") && (
          <Typography style={{ color: theme.colors.text.secondary }}>
            사진&nbsp;
          </Typography>
        )}
        <Typography bold>
          {photo?.current || 0} / {photo?.required || 0}
        </Typography>
      </div>
      <div>
        <IconContentVideo />
        {!isBreakpoint("small") && (
          <Typography style={{ color: theme.colors.text.secondary }}>
            영상&nbsp;
          </Typography>
        )}
        <Typography bold>
          {video?.current || 0} / {video?.required || 0}
        </Typography>
      </div>
      <div>
        <IconContentText />
        {!isBreakpoint("small") && (
          <Typography style={{ color: theme.colors.text.secondary }}>
            텍스트&nbsp;
          </Typography>
        )}
        <Typography bold>
          {text?.current || 0} / {text?.required || 0}
        </Typography>
      </div>
    </Wrapper>
  );
};

export default Count;
