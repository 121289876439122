import Axios from "axios";
import { Auth } from "components/AuthHelper";
import { Project } from "types/project";
// import qs from "querystring";

const { create } = Axios;

interface ProjectResponse {
  code: string;
  result: Project;
}

export interface Music {
  _id: string;
  musicTitle: string;
  musicLength: number;
  musicInstrument: number[];
  musicGenre: number;
  musicMood: number;
  musicDescription: string;
  musicUrl: string;
  createdAt: Date;
  updatedAt: Date;
  musicBpm: number;
}

interface MusicListResponse {
  code: string;
  result: Music[];
  resultTotalCount: number;
  NumberPerPage: number;
  currentPage: number;
  pageCount: number;
  pageCounter: number;
  hasPrev: boolean;
  hasNext: boolean;
  prev?: number | null;
  next?: number | null;
}

function api() {
  const { token: accessToken, type: tokenType }: Auth = JSON.parse(
    window.localStorage.getItem("auth") || "{}"
  );

  const withToken =
    accessToken && tokenType === "accessToken"
      ? {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
        }
      : {};

  const defaultParams =
    accessToken && tokenType === "userId"
      ? {
          userId: accessToken,
        }
      : {};

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "ko",
    ...withToken,
  };

  const axios = create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers,
  });
  axios.defaults.headers = headers;

  const projects = {
    async findOne(projectId: string): Promise<Project | undefined> {
      const res = await axios.post<ProjectResponse>(
        `/subtemplate/${projectId}`,
        { ...defaultParams }
      );
      if (res?.data?.code === "SUCCESS" && res.data.result) {
        return res.data.result;
      }
      return undefined;
    },
    async patch(
      projectId: string,
      data: {
        userSource?: (string | null)[][];
        bgm?: string | null;
        bgmUrl?: string | null;
        changedColor?: (string | null)[];
        lastSceneType?: string | number | null;
        contactNumber?: string | null;
      }
    ): Promise<Project | undefined> {
      const res = await axios.patch<ProjectResponse>(
        `/subtemplate/${projectId}`,
        { ...defaultParams, ...data }
      );
      if (res?.data?.code === "SUCCESS" && res.data.result) {
        return res.data.result;
      }
      return undefined;
    },

    async changeStatus(
      projectId: string,
      status: number
    ): Promise<Project | undefined> {
      const res = await axios.patch<Project>(
        `/subtemplate/${projectId}/status`,
        { ...defaultParams, status }
      );
      if (res?.data?._id) {
        return res.data;
      }
      return undefined;
    },
  };

  const music = {
    async list(data: {
      limit?: number;
      page?: number;
      moods?: number[];
      genres?: number[];
    }) {
      const {
        limit = 10,
        page = 1,
        moods: musicMood = [0],
        genres: musicGenre = [0],
      } = data;
      const result = await axios.get<MusicListResponse>("/music", {
        params: {
          limit,
          page,
          musicMood: musicMood.join(","),
          musicGenre: musicGenre.join(","),
        },
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data;
      }
      return result?.data || {};
    },
  };
  return { projects, music };
}

export default api;
