import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import Cropper from "react-easy-crop";
import Typography from "components/Typography";
import Button from "components/Button";
import Trimmer from "./Trimmer";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const CropperWrapper = styled.div<{ aspect?: number }>`
  width: 100%;
  height: auto;
  aspect-ratio: ${({ aspect }) => aspect || 16 / 9};
  position: relative;
  margin-bottom: 16px;
`;

// CropAndTrimVideo.propTypes = {
//   video: PropTypes.string,
//   duration: PropTypes.number,
//   videoSize: { width: PropTypes.number, height: PropTypes.number },
//   onChange: PropTypes.func,
// };

type CropVideoProps = {
  video: string;
  thumbnailSrc?: string;
  duration: number;
  videoSize: { width: number; height: number };
  handleMediaError?: () => void;
  onComplete(data: {
    x: number;
    y: number;
    width: number;
    height: number;
    startTime: number;
  }): void;
};

const CropVideo: React.FC<CropVideoProps> = ({
  video: videoUrl,
  thumbnailSrc,
  duration,
  videoSize,
  handleMediaError,
  onComplete: handleComplete,
}) => {
  const theme = useTheme();
  const cropperRef = useRef<any>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [computed, setComputed] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [startTime, setStartTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [originalDuration, setOriginalDuration] = useState<number | null>(null);
  const aspect = useMemo(() => {
    return videoSize.width / videoSize.height;
  }, [videoSize]);

  const handleVideoTimeUpdate = useCallback(() => {
    try {
      const video = cropperRef?.current?.videoRef;
      if (video) {
        if (
          video.currentTime > startTime + duration ||
          video.currentTime < startTime
        ) {
          video.currentTime = startTime;
        }
        video.play();
        setCurrentTime(video.currentTime);
      }
    } catch (error) {
      console.log(error);
    }
  }, [startTime, duration]);

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setComputed(croppedAreaPixels);
  }, []);

  const handleTrimmerChange = useCallback((value) => {
    setStartTime(value);
  }, []);

  useEffect(() => {
    const video: HTMLVideoElement = cropperRef?.current?.videoRef;
    const handleLoadedMetaData = () => {
      if (duration <= video.duration) {
        video.currentTime = startTime
        setOriginalDuration(video.duration)
      } else {
        setOriginalDuration(null);
        handleMediaError && handleMediaError();
      }
    };
    video.addEventListener("timeupdate", handleVideoTimeUpdate);
    video.addEventListener("loadedmetadata", handleLoadedMetaData);

    return () => {
      video.removeEventListener("timeupdate", handleVideoTimeUpdate);
      video.removeEventListener("loadedmetadata", handleLoadedMetaData);
    };
  }, [startTime, duration, videoUrl, handleVideoTimeUpdate]);

  useEffect(() => {
    if (originalDuration !== null) {
      if (originalDuration > 0) {
        setIsLoaded(false);
      } else if (originalDuration === -1) {
        alert("영상의 길이가 너무 짧습니다.");
        setOriginalDuration(null);
      }
    }
  }, [videoUrl, originalDuration]);

  return (
    <Wrapper>
      {videoUrl && (
        <>
          <Typography
            type="body2"
            block
            style={{
              textAlign: "center",
              marginBottom: 16,
              color: theme.colors.text.secondary,
            }}
          >
            불러온 영상을 사용할 영역만큼 자르고, <br />
            영상의 구간을 설정할 수 있습니다.
          </Typography>
          <CropperWrapper>
            {typeof originalDuration === 'number' && originalDuration > 0 && <div>로딩 중...</div>}
            <Cropper
              ref={cropperRef}
              aspect={aspect}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              video={videoUrl}
              onCropComplete={handleCropComplete}
            />
          </CropperWrapper>
          <Typography
            style={{
              display: "block",
              marginBottom: 16,
              position: "relative",
              zIndex: 1,
            }}
          >
            비디오 삽입 구간 설정
          </Typography>
          {typeof originalDuration === 'number' && originalDuration > 0 && (
            <Trimmer
              duration={duration}
              onChange={handleTrimmerChange}
              currentTime={currentTime}
              originalDuration={originalDuration}
              value={startTime}
              style={{ marginBottom: 16 }}
              thumbnailSrc={thumbnailSrc}
            />
          )}
          <div style={{ textAlign: "right" }}>
            <Button
              primary
              onClick={() =>
                handleComplete && handleComplete({ ...computed, startTime })
              }
            >
              완료
            </Button>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default CropVideo;
