import { useState, useEffect, useCallback } from "react";
import Cropper from "react-easy-crop";
import styled, { useTheme } from "styled-components";

import {
  iconReverseHorizontal,
  iconReverseVertical,
  iconRotate,
} from "assets/icons";
import Typography from "components/Typography";
import Button from "components/Button";
import cropImage from "utils/cropImage";
import flipImage from "utils/flipImage";
import { rotateImage } from "utils/rotateImage";
import AspectRatioSizer from "components/AspectRatioSizer";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 48px;
`;

const CropWrapper = styled.div<{ aspect?: number }>`
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border};
`;

const FunctionWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & > div {
    width: 100%;
    height: auto;
    max-width: 360px;
    margin: 0 auto;
    padding: 16px;
    position: relative;
    display: flex;
  }
`;

const IconFunction = styled.div<{ icon: string }>`
  flex: 1;
  width: auto;
  height: auto;
  position: relative;
  display: inline-block;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 48px;
    position: relative;
    display: block;
    background-image: url("${({ icon }) => icon}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 8px;
  }
`;

type CropImageProps = {
  image?: string;
  aspect?: number;
  onCrop?(src: string): void;
  restrictPosition?: boolean;
};

const CropImage: React.FC<CropImageProps> = ({
  image: imageSrc = "",
  aspect = 1,
  onCrop: handleCrop = () => {},
  restrictPosition = true,
}) => {
  // image: 'your-image-url or as base64',
  // crop: { x: 0, y: 0 },
  // zoom: 1,
  // aspect: 4 / 3,
  const theme = useTheme();
  const [image, setImage] = useState(imageSrc);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(360);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isCropping, setIsCropping] = useState(false);

  const [flipH, setFlipH] = useState(false);
  const [flipV, setFlipV] = useState(false);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleCropChange = setCrop;
  const handleZoomChange = setZoom;
  const handleCropComplete = useCallback((croppedArea, _croppedAreaPixels) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  }, []);
  const handleMediaLoaded = () => {
    setIsLoaded(true);
  };

  const handleComplete = useCallback(async () => {
    setIsCropping(true);
    try {
      const croppedImage = await cropImage(
        image,
        croppedAreaPixels,
        0
        // rotation
      );
      handleCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
    setTimeout(() => {
      setIsCropping(false);
    }, 255);
  }, [croppedAreaPixels, image, handleCrop]);

  const handleFlipAndRotate = useCallback(async () => {
    setIsLoaded(false);
    const flipResult = await flipImage(imageSrc, flipH, flipV);
    const result = await rotateImage(flipResult, rotation);
    setImage(result);
    setIsLoaded(true);
  }, [imageSrc, flipH, flipV, rotation]);

  useEffect(() => {
    handleFlipAndRotate();
  }, [handleFlipAndRotate, flipH, flipV, rotation]);

  useEffect(() => {
    setImage(imageSrc);
  }, [imageSrc]);

  return (
    <Wrapper>
      <Typography
        type="body2"
        block
        style={{
          textAlign: "center",
          marginBottom: 16,
          color: theme.colors.text.secondary,
        }}
      >
        불러온 사진을 사용할 영역만큼 자르거나 좌우/상하반전 또는 회전할 수
        있습니다.
        <br />
        이미지 확대와 축소는 마우스 스크롤로 조절할 수 있습니다.
      </Typography>
      {!isLoaded && <div>로딩 중...</div>}
      <CropWrapper>
        <AspectRatioSizer aspect={aspect} />
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Cropper
            {...{ image, crop, zoom, aspect, restrictPosition }}
            onCropChange={handleCropChange}
            onZoomChange={handleZoomChange}
            // onRotationChange={setRotation}
            onCropComplete={handleCropComplete}
            onMediaLoaded={handleMediaLoaded}
            minZoom={restrictPosition ? 1 : 0}
          />
        </div>
      </CropWrapper>
      <FunctionWrapper>
        <div>
          <IconFunction
            icon={iconReverseHorizontal}
            onClick={() => {
              setFlipH((current) => !current);
            }}
          >
            <Typography
              type="body2"
              bold
              style={{ color: theme.colors.text.secondary }}
            >
              좌우반전
            </Typography>
          </IconFunction>
          <IconFunction
            icon={iconReverseVertical}
            onClick={() => {
              setFlipV((current) => !current);
            }}
          >
            <Typography
              type="body2"
              bold
              style={{ color: theme.colors.text.secondary }}
            >
              상하반전
            </Typography>
          </IconFunction>
          <IconFunction
            icon={iconRotate}
            onClick={() => {
              setRotation((current) => {
                if (current - 90 <= 0) {
                  return 360;
                }
                return current - 90;
              });
            }}
          >
            <Typography
              type="body2"
              bold
              style={{ color: theme.colors.text.secondary }}
            >
              회전
            </Typography>
          </IconFunction>
        </div>
      </FunctionWrapper>
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          padding: 16,
          textAlign: "right",
        }}
      >
        <Button
          primary
          size="default"
          style={{ margin: 0 }}
          onClick={() => {
            handleComplete();
          }}
          disabled={isCropping}
        >
          {!isCropping && "확인"}
          {isCropping && "처리중"}
        </Button>
      </div>
    </Wrapper>
  );
};

export default CropImage;
