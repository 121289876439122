import AspectRatioSizer from "components/AspectRatioSizer";
import Typography from "components/Typography";
import styled from "styled-components";

type SceneItemProps = {
  selected?: boolean;
  touched?: boolean;
  completed?: boolean;
  onClick?(): void;
  index?: number;
  thumbnailSrc?: string;
};

const Wrapper = styled.button.attrs<SceneItemProps>(({ thumbnailSrc }) => ({
  style: { backgroundImage: `url('${thumbnailSrc}')` },
}))<SceneItemProps>`
  display: inline-block;
  min-width: 128px;
  height: auto;
  position: relative;
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.text.primary};
    opacity: ${({ selected }) => (selected ? 0 : 0.5)};
    pointer-events: none;
    transition: opacity 0.125s ease-in-out;
    z-index: 10;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
    border: 3px solid
      ${({ theme, selected, completed }) => {
        if (selected && completed) {
          return theme.colors.success;
        }
        if (selected && !completed) {
          return theme.colors.danger;
        }
        return "transparent";
      }};
  }

  &:hover {
    &::before {
      opacity: 0;
    }
  }
`;

Wrapper.defaultProps = {
  type: "button",
};

const SceneNumber = styled.div`
  width: auto;
  height: auto;
  display: inline-block;
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.text.primary};
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
  border-radius: 4px;
  pointer-events: none;
  user-select: none;
  z-index: 10;
`;

const SceneItem = (props: SceneItemProps) => {
  const {
    index = 0,
    selected,
    touched,
    completed,
    onClick,
    thumbnailSrc,
  } = props;
  return (
    <Wrapper
      selected={selected}
      touched={touched}
      completed={completed}
      onClick={onClick}
      thumbnailSrc={thumbnailSrc}
    >
      <AspectRatioSizer aspect={16 / 9} />
      <SceneNumber>
        <Typography type="caption1"># {`0${index}`.slice(-2)}</Typography>
      </SceneNumber>
    </Wrapper>
  );
};

export default SceneItem;
