import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import {
  iconContentPhoto,
  iconContentVideo,
  iconContentText,
} from "assets/icons";

const icons = {
  photo: {
    default: iconContentPhoto,
    active: iconContentPhoto,
    completed: iconContentPhoto,
  },
  video: {
    default: iconContentVideo,
    active: iconContentVideo,
    completed: iconContentVideo,
  },
  text: {
    default: iconContentText,
    active: iconContentText,
    completed: iconContentText,
  },
};

const Wrapper = styled.g`
  @keyframes op {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.2;
    }
    80% {
      opacity: 1;
    }
  }

  transition: opacity 0.2s ease-in-out;
  .hover & {
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }
  }

  cursor: pointer;
  &:hover polygon,
  &.completed:hover polygon {
    animation: op 0.8s ease-in-out infinite 0s;
    z-index: 30;
  }
`;

const Polygon = styled.polygon`
  fill: ${({ theme }) => theme.colors.text.primary + "7f"};
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 1;
  /* opacity: 0.5; */

  transition: all 0.2s ease-in-out;

  &.completed {
    fill: ${({ theme }) => theme.colors.success + "7f"};
    stroke: ${({ theme }) => theme.colors.success};
  }
`;

const Text = styled.text`
  fill: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 700;
`;

Text.defaultProps = {
  textAnchor: "middle",
  alignmentBaseline: "middle",
  kerning: -2,
};

const Photo = styled.image`
  width: 128px;
`;

Photo.defaultProps = {
  alignmentBaseline: "middle",
};

const IconWrapper = styled.rect`
  fill: ${({ theme }) => theme.colors.border};
`;

IconWrapper.defaultProps = {
  width: 36,
  height: 36,
  x: 8,
  y: 8,
  rx: 3,
  ry: 3,
};

const ICON_WIDTH = 40;

const getCenterOfPolygonFromPoints = (_points: [number, number][]) => {
  try {
    const arrayX = _points.map((point) => point[0]);
    const arrayY = _points.map((point) => point[1]);
    const minX = Math.min(...arrayX);
    const maxX = Math.max(...arrayX);
    const minY = Math.min(...arrayY);
    const maxY = Math.max(...arrayY);
    return [minX + (maxX - minX) / 2, minY + (maxY - minY) / 2];
  } catch (error) {
    return [0, 0];
  }
};

const omitText = (text: string, length: number) => {
  if (`${text || ""}`.length > length) {
    return `${text || ""}`.substr(0, length) + "...";
  }
  return text;
};

interface AreaProps {
  points: [number, number][];
  type?: "I" | "T" | "V" | string;
  size?: { width?: number; height?: number };
  iconPath?: { x?: number; y?: number };
  thumbnailPath?: { x?: number; y?: number };
  data?: string;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  textLength?: number;
  isLoading?: boolean;
}

const Area = ({
  points,
  data = "",
  type = "T",
  onClick: handleClick = () => {},
  size,
  iconPath: customIconPath,
  thumbnailPath: customThumbnailPath,
  onMouseEnter: handleMouseEnter,
  onMouseLeave: handleMouseLeave,
  textLength,
  isLoading,
}: AreaProps) => {
  const theme = useTheme();
  const firstPoint = useMemo(() => {
    return points[0];
  }, [points]);
  const relativePoints = useMemo(() => {
    return JSON.parse(JSON.stringify(points)).map((point: number[]) => {
      // eslint-disable-next-line no-param-reassign
      point[0] -= firstPoint[0];
      // eslint-disable-next-line no-param-reassign
      point[1] -= firstPoint[1];
      return point;
    });
  }, [points, firstPoint]);

  const thumbnailSize = useMemo(() => {
    const result = {
      width: size?.width ? size?.width / 4 : 80,
      height: size?.height ? size?.height / 4 : 80,
    };
    if (result.width < 80) {
      result.width = 80;
    }
    if (result.width > 160) {
      result.width = 160;
    }
    if (result.height < 80) {
      result.height = 80;
    }
    if (result.height > 160) {
      result.height = 160;
    }
    if (typeof size?.width === "number" && typeof size?.height === "number") {
      if (size?.width > size?.height) {
        result.height = (result.width * size?.height) / size?.width;
      } else {
        result.width = (result.height * size?.width) / size?.height;
      }
    }
    return result;
  }, [size]);

  const thumbnailPath = useMemo(() => {
    if (
      typeof customThumbnailPath?.x === "number" &&
      typeof customThumbnailPath?.y === "number"
    ) {
      const result = { x: customThumbnailPath.x, y: customThumbnailPath.y };
      if (result.x < 0) {
        result.x = firstPoint[0] + 15;
      }
      if (result.y < 0) {
        result.y = firstPoint[1] + 15;
      }
      return result;
    }
    const result = {
      x: getCenterOfPolygonFromPoints(points)[0] - thumbnailSize.width / 2,
      y: getCenterOfPolygonFromPoints(points)[1] - thumbnailSize.height / 2,
    };
    return result;
  }, [customThumbnailPath, points, thumbnailSize, firstPoint]);

  const relativeThumbnailPath = useMemo(() => {
    return {
      x: thumbnailPath.x - firstPoint[0],
      y: thumbnailPath.y - firstPoint[1],
    };
  }, [firstPoint, thumbnailPath]);

  const iconPath = useMemo(() => {
    if (data && type !== "T") {
      const result = {
        x: thumbnailPath.x + thumbnailSize.width - ICON_WIDTH / 2,
        y: thumbnailPath.y + thumbnailSize.height - ICON_WIDTH / 2,
      };

      return result;
    }

    if (
      typeof customIconPath?.x === "number" &&
      typeof customIconPath?.y === "number"
    ) {
      const result = { x: customIconPath.x, y: customIconPath.y };
      if (result.x < 0) {
        result.x = firstPoint[0] + 15 + ICON_WIDTH / 2;
      }
      if (result.y < 0) {
        result.y = firstPoint[1] + 15 + ICON_WIDTH / 2;
      }
      return result;
    }
    const result = {
      x: getCenterOfPolygonFromPoints(points)[0],
      y: getCenterOfPolygonFromPoints(points)[1],
    };
    return result;
  }, [
    customIconPath,
    points,
    thumbnailPath,
    data,
    thumbnailSize,
    type,
    firstPoint,
  ]);

  const relativeIconPath = useMemo(() => {
    return {
      x: iconPath.x - firstPoint[0],
      y: iconPath.y - firstPoint[1],
    };
  }, [firstPoint, iconPath]);

  return (
    <>
      <Wrapper
        transform={`translate(${firstPoint.join(",")})`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <defs>
          <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
            <feFlood floodColor={theme.colors.text.primary} />
            <feComposite in="SourceGraphic" operator="xor" />
          </filter>
        </defs>
        <Polygon
          className={data ? "completed" : ""}
          points={relativePoints
            .map((point: number[]) => {
              return point.join(",");
            })
            .join(" ")}
        />

        {isLoading && (
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={relativeIconPath.x - ICON_WIDTH / 2}
            y={relativeIconPath.y - ICON_WIDTH / 2}
            viewBox="0 0 100 100"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve"
            width={56}
            height={56}
          >
            <path
              fill="#fff"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        )}

        {!isLoading && (
          <>
            {type === "T" && (
              <>
                <image
                  width={ICON_WIDTH}
                  href={icons.text[data ? "completed" : "default"]}
                  x={relativeIconPath.x - ICON_WIDTH / 2}
                  y={relativeIconPath.y - ICON_WIDTH / 2}
                />
                {/* 텍스트 배경색 https://stackoverflow.com/questions/15500894/background-color-of-text-in-svg */}
                <Text
                  filter="url(#solid)"
                  x={relativeIconPath.x}
                  y={relativeIconPath.y}
                >
                  {omitText(data, textLength || 10)}
                </Text>
                <Text x={relativeIconPath.x} y={relativeIconPath.y}>
                  {omitText(data, textLength || 10)}
                </Text>
              </>
            )}
            {type === "I" && (
              <>
                {data && (
                  <Photo
                    x={relativeThumbnailPath.x}
                    y={relativeThumbnailPath.y}
                    style={{
                      ...thumbnailSize,
                    }}
                    href={`${data}`}
                  />
                )}
                <image
                  width={ICON_WIDTH}
                  href={icons.photo[data ? "completed" : "default"]}
                  x={relativeIconPath.x - ICON_WIDTH / 2}
                  y={relativeIconPath.y - ICON_WIDTH / 2}
                />
              </>
            )}
            {type === "V" && (
              <>
                {data && (
                  <Photo
                    x={relativeThumbnailPath.x}
                    y={relativeThumbnailPath.y}
                    style={{
                      ...thumbnailSize,
                    }}
                    href={`${data}/thumbnail`}
                  />
                )}
                <image
                  width={ICON_WIDTH}
                  href={icons.video[data ? "completed" : "default"]}
                  x={relativeIconPath.x - ICON_WIDTH / 2}
                  y={relativeIconPath.y - ICON_WIDTH / 2}
                />
              </>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Area;
