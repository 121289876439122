import Range from "components/Range";
import React, { useEffect, useCallback } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const Time = styled.div`
  height: 100%;
  position: absolute;
  top: 50%;
  /* margin-left: 4px; */
  transform: translateY(-50%);
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primary}cc;
  z-index: 30;
  transition: width 0.2s linear;
`;

type TrimmerProps = {
  duration: number;
  originalDuration: number;
  currentTime: number;
  onChange?(value: number): void;
  value: number;
  style?: React.CSSProperties;
  thumbnailSrc?: string;
};

const Trimmer: React.FC<TrimmerProps> = ({
  value,
  duration,
  originalDuration,
  style,
  currentTime,
  onChange: setValue = () => {},
  thumbnailSrc,
}) => {
  // const [isDone, setIsDone] = useState(false);

  const handleSliderChange = useCallback(
    (newValue: number) => {
      if (newValue <= 0) {
        setValue(0);
      } else if (newValue + duration >= originalDuration) {
        setValue(originalDuration - duration);
      } else {
        setValue(newValue);
      }
    },
    [duration, originalDuration, setValue]
  );

  useEffect(() => {
    // setValue(duration / 2);
    setValue(0);
  }, [duration, setValue]);

  return (
    <Wrapper style={style}>
      <Range
        background={thumbnailSrc}
        value={value}
        originalDuration={originalDuration}
        duration={duration}
        onChange={handleSliderChange}
      />
      <Time
        style={{
          width: `${((currentTime - value) / originalDuration) * 100}%`,
          left: `${(value / originalDuration) * 100}%`,
        }}
      />
    </Wrapper>
  );
};

export default Trimmer;
