import Modal, { ModalProps } from "components/Modal";
import Typography from "components/Typography";
import styled, { useTheme } from "styled-components";

import imgExample from "assets/images/img-editor-contact-example.png";
import Button from "components/Button";
import { useEffect, useRef, useState } from "react";
import Input from "components/Input";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  padding: 36px;
`;

type ContactModalProps = ModalProps & {
  onComplete?(contactNumber: string | null): void;
};

const ContactModal: React.FC<ContactModalProps> = ({
  visible,
  onClose,
  onComplete,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setPage(0);
      }, 250);
    }
  }, [visible]);
  return (
    <Modal
      title="추가 매장 정보 입력"
      visible={visible}
      closable
      fitContent
      onClose={onClose}
      onGoBack={() => setPage((current) => current - 1)}
      backButton={page > 0 ? <Typography>&lt; 이전</Typography> : false}
    >
      {page === 0 && (
        <Wrapper>
          <div style={{ marginBottom: 16 }}>
            <Typography type="body1" bold block>
              추가 매장 정보를 입력하시겠습니까?
            </Typography>
          </div>
          <div>
            <Typography style={{ textAlign: "start" }} type="body2" block>
              매장명 또는 문의번호 등의 추가 매장 정보를 입력하세요.<br />입력된 추가 매장 정보는 좌측 상단에 표기되며<br />입력하지 않아도 제작을 완료할 수 있습니다. <br />
              <br />
            </Typography>
          </div>
          <div style={{ marginBottom: 16, textAlign: "center" }}>
            <img
              src={imgExample}
              style={{ width: "100%", maxWidth: 320, marginBottom: 4 }}
              alt="추가 매장 정보 적용 예시"
            />
            <Typography
              type="caption1"
              block
              style={{ color: theme.colors.text.secondary }}
            >
              예시 이미지
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
            }}
          >
            <Button
              secondary
              size="small"
              onClick={() => onComplete && onComplete(null)}
            >
              건너뛰기
            </Button>
            <Button
              primary
              size="small"
              onClick={() => setPage((current) => current + 1)}
            >
              입력하기
            </Button>
          </div>
        </Wrapper>
      )}
      {page === 1 && (
        <Wrapper>
          <div style={{ marginBottom: 16 }}>
            <Typography type="body2" bold>
              추가 매장 정보를 입력해주세요.
            </Typography>
          </div>
          <div
            style={{
              marginBottom: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
            }}
          >
            <Input
              ref={inputRef}
              placeholder="전화번호 입력 또는 매장명 입력"
            />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 16,
            textAlign: "start",
            color: "#666"
          }}>
            <div style={{ width: 180 }}>
              <Typography type="body2" block>
                예시1) 문의 전화번호<br />문의 02-1111-2222<br /><br />예시2) 매장명<br />하남돼지집 강남점
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
              width: 320,
            }}
          >
            <Button secondary size="small" onClick={() => setPage(0)}>
              뒤로가기
            </Button>
            <Button
              primary
              size="small"
              onClick={() =>
                onComplete && onComplete(inputRef?.current?.value || "")
              }
            >
              입력 완료
            </Button>
          </div>
        </Wrapper>
      )}
    </Modal>
  );
};

export default ContactModal;
