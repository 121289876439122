import qs from "querystring";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

export type TokenType = "accessToken" | "userId";
export type Auth = { token?: string; type: TokenType };

const AuthHelper: React.FunctionComponent = ({ children }) => {
  const location = useLocation();
  const [auth, setAuth] = useLocalStorage<Auth>("auth", {
    token: "",
    type: "accessToken",
  });

  useEffect(() => {
    const queryString = qs.parse(location.search.replace("?", "")) as Auth;
    console.log(queryString);
    const type: TokenType = queryString?.type || "accessToken";
    const token = queryString?.token || "";
    setAuth({ token, type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{auth?.token && children}</>;
};

export default AuthHelper;
