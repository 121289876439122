import { css, CSSProp } from "styled-components";

export const sizes = {
  small: 480,
  medium: 640,
  large: 1024,
  xLarge: 1366,
  xxLarge: 1920,
  xxxLarge: 9999999,
};

export type Sizes = typeof sizes;

export type Breakpoints = {
  [k in keyof typeof sizes]: (
    args: ReturnType<typeof css>
  ) => CSSProp | undefined;
};
const breakpoints = Object.keys(sizes).reduce(
  (media, key) => ({
    ...media,
    [key]: (args: ReturnType<typeof css>) =>
      css`
        @media only screen and (max-width: ${(sizes as any)[key]}px) {
          ${args}
        }
      `,
  }),
  {} as Breakpoints
);

export default breakpoints;
