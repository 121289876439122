import {
  iconBGMActive,
  iconBGM,
  iconMainColor,
  iconMainColorActive,
  iconSubColor,
  iconSubColorActive,
} from "assets/icons";
import Container from "components/Container";
import Typography from "components/Typography";
import ColorPanel from "components/ColorPanel";
import MusicPanel from "components/MusicPanel";
import Modal, { useModal } from "components/Modal";
import styled, { useTheme } from "styled-components";

import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Project } from "types/project";
import { files, service } from "api";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import useBreakpoint from "hooks/useBreakpoint";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Count from "./Count";
import ProgressBar from "./ProgressBar";
import Screen from "./Screen";
import SceneList from "./SceneList";
import TextModal from "./TextModal";
import PhotoModal from "./PhotoModal";
import VideoModal from "./VideoModal";
import ContactModal from "./ContactModal";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: fill-available;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundEditor};
`;

const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: top;
  justify-content: center;
`;

const parseProjectData = (data: Project) => {
  const newData = { ...data };
  const lastSceneType = newData.lastSceneType;
  const lastSceneIndex = newData?.design?.sources.length - 1;
  const lastSceneFromOriginal = newData?.design?.sources[lastSceneIndex];
  const lastSceneFromDesign = newData?.design?.lastsources?.[lastSceneType - 1];
  if (lastSceneFromOriginal) {
    if (lastSceneFromDesign) {
      lastSceneFromOriginal.sceneImage =
        newData?.design?.lastsources[lastSceneType - 1].sceneImage;
    }
    let otIndex = 0;
    let olIndex = 0;
    lastSceneFromOriginal.source = lastSceneFromOriginal.source
      .map((item) => {
        if (item.sourceType === "OT") {
          const lastSceneSource = lastSceneFromDesign?.source?.filter(
            ({ sourceType }) => {
              return sourceType === "OT";
            }
          )[otIndex++];
          if (lastSceneSource) {
            return lastSceneSource;
          }
        }

        if (item.sourceType === "OL") {
          const lastSceneSource = lastSceneFromDesign?.source?.filter(
            ({ sourceType }) => {
              return sourceType === "OL";
            }
          )[olIndex++];
          if (lastSceneSource) {
            return lastSceneSource;
          }
        }

        return item;
      })
      .map((item) => {
        const newItem = { ...item };
        if (lastSceneType === 3 || lastSceneType === 1) {
          if (newItem.sourceType === "OT") {
            newItem.sourceType = "T";
          }
        }

        if (lastSceneType === 3 || lastSceneType === 2) {
          if (newItem.sourceType === "OL") {
            newItem.sourceType = "I";
          }
        }
        return newItem;
      });
    // .filter(({ sourceType }) =>
    //   ["I", "V", "T"].includes(`${sourceType}`.toUpperCase())
    // );
    newData.design.sources[lastSceneIndex] = lastSceneFromOriginal;
  }
  return newData;
};

const Editor = () => {
  const history = useHistory();
  const theme = useTheme();
  const { isBreakpoint } = useBreakpoint();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<Project>();
  const [projectReadonly, setProjectReadonly] = useState<Project>();
  const [selectedDrawerIndex, setSelectedDrawerIndex] = useState(-1);
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(0);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(-1);
  const [processingSources, setProcessingSources] = useState<
    { sceneIndex: number; sourceIndex: number }[]
  >([]);
  const userSources = useMemo(() => {
    return project?.sources;
  }, [project]);
  const sceneList = useMemo(() => {
    return (project?.design?.sources || []).map((scene, index) => {
      return {
        ...scene,
        isCompleted:
          (userSources?.[index]?.filter((item) => !item).length || 0) <= 0,
      };
    });
  }, [project, userSources]);
  const selectedSourceType = useMemo(() => {
    return sceneList?.[selectedSceneIndex]?.source?.[selectedSourceIndex]
      ?.sourceType;
  }, [sceneList, selectedSourceIndex, selectedSceneIndex]);
  const editModal = useModal();
  const contactModal = useModal();
  const renderModal = useModal();

  const isEditable = useMemo(() => {
    if (project?.status === 1) {
      return false;
    }
    return true;
  }, [project]);

  const isRendering = useMemo(() => {
    const status = project?.status || 0;
    return status >= 1 && status <= 2;
  }, [project]);

  const patchUserSource = (
    sceneIndex: number,
    sourceIndex: number,
    value: string
  ) => {
    setProject((current) => {
      if (current) {
        const newUserSources = [...current.sources];
        newUserSources[sceneIndex][sourceIndex] = value;
        return { ...current, sources: newUserSources };
      }
      return undefined;
    });
  };

  const fetchProject = useCallback(async () => {
    const res = await service().projects.findOne(projectId);
    if (res) {
      setProject(parseProjectData(res));
      setProjectReadonly(parseProjectData(res));
    }
  }, [projectId]);

  const sendGoBackToParent = useCallback(() => {
    if (typeof window?.parent?.postMessage === "function") {
      window.parent.postMessage(
        {
          projectId,
        },
        "*"
      );
    }
  }, [projectId]);

  const handleRenderClick = useCallback(async () => {
    const res = await service().projects.changeStatus(projectId, 1);
    if (res?._id) {
      renderModal.close();
      sendGoBackToParent();
      fetchProject();
    }
  }, [fetchProject, projectId, renderModal, sendGoBackToParent]);

  useEffect(() => {
    if (projectId) {
      fetchProject();
    }
  }, [fetchProject, projectId]);

  useEffect(() => {
    if (projectId && (isRendering || !isEditable)) {
      const timerId = setInterval(() => {
        if (window.navigator.onLine && window.document.hasFocus()) {
          fetchProject();
        }
      }, 2500);
      return () => {
        if (timerId) {
          clearInterval(timerId);
        }
      };
    }
    return undefined;
  }, [fetchProject, isEditable, isRendering, projectId]);

  useEffect(() => {
    const saveProject = async () => {
      try {
        if (project) {
          const res = await service().projects.patch(project._id, {
            userSource: project?.sources,
            bgmUrl: project?.bgmUrl,
            bgm: project?.bgm,
            changedColor: project?.changedColor,
            lastSceneType: project?.lastSceneType,
            contactNumber: project?.contactNumber,
          });
          if (res) {
            // setProject(res);
            setProjectReadonly(parseProjectData(res));
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    saveProject();
  }, [project]);

  const completedNumbers = useMemo(() => {
    const result = {
      video: 0,
      image: 0,
      text: 0,
    };
    if (Array.isArray(project?.design.sources)) {
      project?.design.sources.forEach((sources, i) => {
        if (Array.isArray(sources?.source)) {
          sources.source.forEach((source, j) => {
            if (project.sources[i][j]) {
              switch (source.sourceType) {
                case "I":
                  result.image += 1;
                  break;
                case "T":
                  result.text += 1;
                  break;
                case "V":
                  result.video += 1;
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
    }
    return result;
  }, [project]);

  useEffect(() => {
    setSelectedSourceIndex(-1);
  }, [selectedSceneIndex]);

  const setSourceProcessing = (
    sceneIndex: number,
    sourceIndex: number,
    isProcessing: boolean = true
  ) => {
    if (isProcessing) {
      setProcessingSources((current) => {
        return [...current, { sceneIndex, sourceIndex }];
      });
      return;
    }
    setProcessingSources((current) => {
      return current.filter(
        (item) =>
          !(item.sceneIndex === sceneIndex && item.sourceIndex === sourceIndex)
      );
    });
  };

  const isSourceProcessing = useCallback(
    (sceneIndex: number, sourceIndex: number) => {
      return !!processingSources.find(
        (item) =>
          item.sceneIndex === sceneIndex && item.sourceIndex === sourceIndex
      );
    },
    [processingSources]
  );
  console.log('@@duration@@')
  console.log(project?.design?.sources?.[selectedSceneIndex]?.source?.[
    selectedSourceIndex
  ]?.sourceVideoTime)
  return (
    <Wrapper>
      <Header
        title={project?.design?.temTitle}
        workProgress={projectReadonly?.workProgress}
        renderProgress={projectReadonly?.renderProgress}
        projectStatus={project?.status}
        onCompleteClick={() => contactModal.open()}
        onGoBackClick={() => {
          sendGoBackToParent();
          setTimeout(() => {
            if (history?.length) {
              history.goBack();
            }
          }, 200);
        }}
      />
      <Layout>
        <Sidebar
          menu={[
            {
              icons: {
                default: iconMainColor,
                active: iconMainColorActive,
              },
              title: "주조색상 변경하기",
              key: "mainColor",
              onClick: () =>
                setSelectedDrawerIndex((current) => (current === 0 ? -1 : 0)),
              drawer: {
                visible: selectedDrawerIndex === 0,
                children: (
                  <ColorPanel
                    defaultColor={project?.design.temColor[0]}
                    currentColor={
                      project?.changedColor[0] || project?.design.temColor[0]
                    }
                    onColorChange={(newColor) => {
                      if (newColor) {
                        setProject((current) => {
                          if (current) {
                            const changedColor = current.changedColor;
                            if (Array.isArray(changedColor)) {
                              changedColor.splice(0, 1, newColor);
                            }
                            return {
                              ...current,
                              changedColor,
                            };
                          }
                          return undefined;
                        });
                      }
                      setSelectedDrawerIndex(-1);
                    }}
                  />
                ),
                onClose: () => setSelectedDrawerIndex(-1),
              },
            },
            {
              icons: {
                default: iconSubColor,
                active: iconSubColorActive,
              },
              title: "보조색상 변경하기",
              key: "subColor",
              onClick: () =>
                setSelectedDrawerIndex((current) => (current === 1 ? -1 : 1)),
              drawer: {
                visible: selectedDrawerIndex === 1,
                children: (
                  <ColorPanel
                    defaultColor={project?.design.temColor[1]}
                    currentColor={
                      project?.changedColor[1] || project?.design.temColor[1]
                    }
                    onColorChange={(newColor) => {
                      if (newColor) {
                        setProject((current) => {
                          if (current) {
                            const changedColor = current.changedColor;
                            if (Array.isArray(changedColor)) {
                              changedColor.splice(1, 1, newColor);
                            }
                            return {
                              ...current,
                              changedColor,
                            };
                          }
                          return undefined;
                        });
                      }
                      setSelectedDrawerIndex(-1);
                    }}
                  />
                ),
                onClose: () => setSelectedDrawerIndex(-1),
              },
            },
            {
              icons: {
                default: iconBGM,
                active: iconBGMActive,
              },
              title: "배경음 변경하기",
              key: "bgm",
              onClick: () =>
                setSelectedDrawerIndex((current) => (current === 2 ? -1 : 2)),
              drawer: {
                visible: selectedDrawerIndex === 2,
                children: (
                  <MusicPanel
                    duration={project?.design?.videoDuration}
                    defaultMusicUrl={project?.design?.temPreviewVideo}
                    currentMusicUrl={project?.bgmUrl}
                    onChange={(newBgmSrc, newBgm) => {
                      setProject(
                        (current) =>
                          current && {
                            ...current,
                            bgmUrl: newBgmSrc,
                            bgm: newBgm?._id || null,
                          }
                      );
                    }}
                    visible={selectedDrawerIndex === 2}
                  />
                ),
                onClose: () => setSelectedDrawerIndex(-1),
              },
            },
          ]}
        />
        <Content>
          {/* <div
            style={{
              maxWidth: 490,
              width: "20%",
              height: "100%",
              position: "relative",
            }}
          /> */}
          <div>
            <Container>
              <div
                style={
                  isBreakpoint("small")
                    ? {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 4,
                      }
                    : { display: "block" }
                }
              >
                <div
                  style={{
                    padding: isBreakpoint("small") ? "20px 16px" : "24px 0",
                  }}
                >
                  <Typography bold style={{ whiteSpace: "nowrap" }}>
                    템플릿 구성요소
                  </Typography>
                </div>
                <Count
                  duration={project?.design?.videoDuration}
                  text={{
                    current: completedNumbers.text,
                    required: project?.design?.needText,
                  }}
                  video={{
                    current: completedNumbers.video,
                    required: project?.design?.needVideo,
                  }}
                  photo={{
                    current: completedNumbers.image,
                    required: project?.design?.needImage,
                  }}
                />
              </div>
              <ProgressBar percent={projectReadonly?.workProgress} />
              <div
                style={{ width: "100%", height: "auto", position: "relative" }}
              >
                {!!project?.design?.lastsources && selectedSceneIndex >= (userSources?.length || 0) - 1 && (
                  <div
                    style={{
                      padding: 4,
                      position: "absolute",
                      right: 0,
                      top: isBreakpoint("small") ? "unset" : 0,
                      bottom: isBreakpoint("small") ? "100%" : "unset",
                      zIndex: 10,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    {[
                      {
                        title: "텍스트만 이용하여 씬을 구성합니다.",
                        button: <Typography type="caption1">텍스트</Typography>,
                      },
                      {
                        title: "이미지만 이용하여 씬을 구성합니다.",
                        button: <Typography type="caption1">이미지</Typography>,
                      },
                      {
                        title:
                          "텍스트와 이미지를 모두 이용하여 씬을 구성합니다.",
                        button: (
                          <Typography type="caption1">
                            텍스트 + 이미지
                          </Typography>
                        ),
                      },
                    ].map((item, index) => {
                      const lastSceneType = index + 1;
                      return (
                        <Tooltip key={item.title} title={item.title}>
                          <Button
                            size="small"
                            primary={lastSceneType === project?.lastSceneType}
                            secondary={lastSceneType !== project?.lastSceneType}
                            onClick={async () => {
                              const res = await service().projects.patch(
                                projectId,
                                { lastSceneType }
                              );
                              if (res) {
                                setProject(parseProjectData({ ...res }));
                              }
                            }}
                          >
                            {item.button}
                          </Button>
                        </Tooltip>
                      );
                    })}
                  </div>
                )}
                <Screen
                  key={sceneList?.[selectedSourceIndex]?.sceneImage}
                  scene={{
                    ...sceneList[selectedSceneIndex],
                    source:
                      sceneList[selectedSceneIndex]?.source?.map(
                        (item, sourceIndex) => ({
                          ...item,
                          isLoading: isSourceProcessing(
                            selectedSceneIndex,
                            sourceIndex
                          ),
                        })
                      ) || [],
                  }}
                  sources={userSources?.[selectedSceneIndex]}
                  onSourceClick={(index) => {
                    if (!isSourceProcessing(selectedSceneIndex, index)) {
                      setSelectedSourceIndex(index);
                      editModal.open();
                    }
                  }}
                />
              </div>
              <SceneList
                sceneList={sceneList}
                selectedSceneIndex={selectedSceneIndex}
                onSceneChange={(index) => setSelectedSceneIndex(index)}
              />
            </Container>
          </div>
          <div />
        </Content>
      </Layout>
      <TextModal
        visible={editModal.visible && selectedSourceType === "T"}
        onClose={editModal.close}
        onComplete={(value) => {
          patchUserSource(selectedSceneIndex, selectedSourceIndex, value);
          editModal.close();
        }}
        textLength={
          project?.design?.sources[selectedSceneIndex]?.source?.[
            selectedSourceIndex
          ]?.sourceTextLength?.[0]
        }
        value={userSources?.[selectedSceneIndex]?.[selectedSourceIndex]}
      />
      <PhotoModal
        visible={editModal.visible && selectedSourceType === "I"}
        onClose={editModal.close}
        onComplete={async (dataUrl) => {
          editModal.close();
          const sceneIndex = selectedSceneIndex;
          const sourceIndex = selectedSourceIndex;
          setSourceProcessing(sceneIndex, sourceIndex);
          try {
            const blob = await (await fetch(dataUrl)).blob();
            const res = await files().files.upload(blob);
            if (res?.url) {
              patchUserSource(sceneIndex, sourceIndex, res?.url);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setSourceProcessing(sceneIndex, sourceIndex, false);
          }
        }}
        value={userSources?.[selectedSceneIndex]?.[selectedSourceIndex]}
        aspect={
          (project?.design?.sources?.[selectedSceneIndex]?.source?.[
            selectedSourceIndex
          ]?.sourceWidth || 1) /
          (project?.design?.sources?.[selectedSceneIndex]?.source?.[
            selectedSourceIndex
          ]?.sourceHeight || 1)
        }
      />
      <VideoModal
        visible={editModal.visible && selectedSourceType === "V"}
        onClose={editModal.close}
        onComplete={async (dataURL, cropData) => {
          editModal.close();
          const sceneIndex = selectedSceneIndex;
          const sourceIndex = selectedSourceIndex;
          setSourceProcessing(sceneIndex, sourceIndex);

          try {
            const selectedSource =
              sceneList[selectedSceneIndex]?.source?.[selectedSourceIndex];
            if (selectedSource) {
              const {
                sourceVideoTime: duration = 0,
                sourceWidth: renderWidth = 0,
                sourceHeight: renderHeight = 0,
              } = selectedSource;
              const blob = await (await fetch(dataURL)).blob();
              const res = await files().files.cropAndTrimVideo({
                file: blob,
                duration,
                renderWidth,
                renderHeight,
                ...cropData,
              });
              if (res?.url) {
                patchUserSource(
                  selectedSceneIndex,
                  selectedSourceIndex,
                  res.url
                );
              }
            }
          } catch (error) {
            console.log(error);
          } finally {
            setSourceProcessing(sceneIndex, sourceIndex, false);
          }
        }}
        value={userSources?.[selectedSceneIndex]?.[selectedSourceIndex]}
        duration={
          project?.design?.sources?.[selectedSceneIndex]?.source?.[
            selectedSourceIndex
          ]?.sourceVideoTime
        }
        videoSize={{
          width:
            project?.design?.sources?.[selectedSceneIndex]?.source?.[
              selectedSourceIndex
            ]?.sourceWidth || 1,
          height:
            project?.design?.sources?.[selectedSceneIndex]?.source?.[
              selectedSourceIndex
            ]?.sourceHeight || 1,
        }}
      />
      <ContactModal
        visible={contactModal.visible}
        onClose={() => {
          contactModal.close();
        }}
        onComplete={(value) => {
          setProject(
            (current) => current && { ...current, contactNumber: value }
          );
          contactModal.close();
          renderModal.open();
        }}
      />
      <Modal
        onClose={() => {
          renderModal.close();
        }}
        visible={renderModal.visible}
        style={{ width: "40vw", maxWidth: "90%", margin: "0 auto" }}
        fitContent
        title="안내"
      >
        <div style={{ textAlign: "center" }}>
          {/* <div style={{ marginBottom: 24 }}>
            <img src={iconWarning} alt="warning" style={{ width: 28 }} />
          </div> */}
          <div style={{ marginBottom: 8 }}>
            <Typography type="body1" bold>
              작업한 프로젝트를
              <br />
              이대로 완료하시겠습니까?
            </Typography>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography type="body2">
              렌더링을 시작하는 동안 프로젝트를 수정할 수 없으며,
              <br />
              렌더링 중에는 편집 데이터가 원할히 저장되지 않을 수 있습니다.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
            }}
          >
            <Button
              secondary
              block
              onClick={() => {
                renderModal.close();
              }}
            >
              취소
            </Button>
            <Button primary block onClick={handleRenderClick}>
              완료
            </Button>
          </div>
        </div>
      </Modal>

      {!isEditable && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 999999,
            backgroundColor: "rgba(0,0,0,0.5)",
            color: theme.colors.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div>
            {(project?.status || 0) < 3 && (
              <Typography>곧 렌더링이 시작됩니다.</Typography>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default Editor;
