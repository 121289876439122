import { forwardRef } from "react";
import styled, { css } from "styled-components";

type InputProps = React.ComponentPropsWithoutRef<"input"> & {
  inputSize?: "small" | "default" | "large";
  block?: boolean;
  prefixElement?: JSX.Element | string;
  suffixElement?: JSX.Element | string;
};

const Wrapper = styled.div<InputProps>`
  display: inline-flex;
  border: 1px solid ${({ theme }) => theme.colors.text.disabled};
  border-radius: 2px;
  ${({ block }) =>
    block &&
    css`
      display: flex;
    `}
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: ${({ inputSize }) => {
    switch (inputSize) {
      case "small":
        return "4px 8px";
      case "large":
        return "8px";
      case "default":
      default:
        return "6px 8px";
    }
  }};
`;

const Input = styled.input<InputProps>`
  flex: 1;
  padding: ${({ inputSize }) => {
    switch (inputSize) {
      case "small":
        return "4px 8px";
      case "large":
        return "8px";
      case "default":
      default:
        return "6px 8px";
    }
  }};
  border: none;
  border-radius: 0;
  width: 100%;
  ${({ block }) =>
    block &&
    css`
      display: block;
    `}
`;

const InputElement = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    prefixElement: prefix,
    suffixElement: suffix,
    // eslint-disable-next-line no-unused-vars
    style,
    ...rest
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper {...props}>
      {prefix}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Input {...rest} ref={ref} />
      {suffix}
    </Wrapper>
  );
});

export default InputElement;
