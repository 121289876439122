import { useEffect, useState } from "react";
import { sizes } from "styles/theme/breakpoints";

const sizeKeys = Object.keys(sizes);

const useBreakpoint = () => {
  const [currentSizeName, setCurrentSizeName] =
    useState<keyof typeof sizes>("large");

  const isBreakpoint = (size: keyof typeof sizes) => {
    return window.innerWidth <= sizes[size];
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setCurrentSizeName(
        (sizeKeys.find((key) => {
          return window.innerWidth <= (sizes as any)[key];
        }) || "large") as keyof typeof sizes
      );
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return { sizeName: currentSizeName, isBreakpoint };
};

export default useBreakpoint;
