import { IconWarning } from "assets/icons";
import Typography from "components/Typography";
import styled, { useTheme } from "styled-components";
import AnimatedNumbers from "react-animated-numbers";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`;

const Progress = styled.div`
  width: 100%;
  height: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 8px;
  & > div {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    transition: width 0.2s ease-in-out;
  }
`;

const ProgressBar = ({ percent = 0 }: { percent?: number }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <div style={{ marginBottom: 13, display: "flex", alignItems: "center" }}>
        <IconWarning />
        <Typography type="body2" style={{ color: theme.colors.text.secondary }}>
          &nbsp;요소를 하나씩 채워보세요
        </Typography>
      </div>
      <div>
        <Progress>
          <div style={{ width: `${percent}%` }} />
        </Progress>
      </div>
      <div>
        <Typography bold>
          <AnimatedNumbers animateToNumber={percent} />
        </Typography>
        <Typography bold>&nbsp;%</Typography>
      </div>
    </Wrapper>
  );
};

export default ProgressBar;
