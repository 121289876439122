import Axios from "axios";

const { create } = Axios;

export type Music = {
  musicTitle: string;
  musicLength: number;
  musicInstrument?: number[] | null;
  musicGenre: number;
  musicMood: number;
  musicDescription: string;
  musicUrl: string;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
};

function api(accessToken?: string) {
  const withToken = accessToken
    ? {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
      }
    : {};

  const headers = {
    "Access-Control-Allow-Origin": "*",
    ...withToken,
  };

  const axios = create({
    baseURL: process.env.REACT_APP_FILES_API_URL,
    withCredentials: false,
    headers,
  });
  axios.defaults.headers = headers;

  const files = {
    async upload(file: Blob) {
      const formData = new FormData();
      formData.append("file", file);

      const result = await axios.post<{
        id: string;
        s3: string;
        type: string;
        url: string;
      }>("/upload/image", formData);
      return result.data;
    },
    async cropAndTrimVideo(data: {
      file: Blob;
      width: number;
      height: number;
      renderWidth: number;
      renderHeight: number;
      x: number;
      y: number;
      startTime: number;
      duration: number;
    }) {
      const {
        file,
        width: cropWidth,
        height: cropHeight,
        renderWidth: scaleWidth,
        renderHeight: scaleHeight,
        x: cropX,
        y: cropY,
        startTime: trimStartTime,
        duration: trimDuration,
      } = data;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("cropWidth", `${cropWidth}`);
      formData.append("cropHeight", `${cropHeight}`);
      formData.append("scaleWidth", `${scaleWidth}`);
      formData.append("scaleHeight", `${scaleHeight}`);
      formData.append("cropX", `${cropX}`);
      formData.append("cropY", `${cropY}`);
      formData.append("trimStartTime", `${trimStartTime}`);
      formData.append("trimDuration", `${trimDuration}`);
      const result = await axios.post<{
        id: string;
        s3: string;
        type: string;
        url: string;
      }>("/upload/video/processing", formData);
      console.log(result);
      return result.data;
    },
  };

  const music = {
    async list(data: { limit: number; page: number }) {
      const { limit = 10, page = 1 } = data;
      const result = await axios.get<{ results: Music[] }>(
        "/download/music/list/info",
        {
          params: { limit, page },
        }
      );
      return result.data;
    },
    async upload(data: {
      musicUrl: string;
      fadeEffect?: boolean;
      trimAudioStart: number;
      trimAudioDuration: number;
    }) {
      const {
        musicUrl,
        fadeEffect = false,
        trimAudioStart,
        trimAudioDuration,
      } = data;
      const result = await axios.post("/upload/music", {
        musicUrl,
        fadeEffect,
        trimAudioStart,
        trimAudioDuration,
      });
      return result.data;
    },
  };

  return {
    files,
    music,
  };
}

export default api;
