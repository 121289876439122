import Editor from "pages/Editor";
import GlobalStyles from "styles/GlobalStyles";
import AuthHelper from "components/AuthHelper";
import { Route, Switch } from "react-router-dom";

import "react-color-palette/lib/css/styles.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <AuthHelper>
        <Switch>
          <Route path="/:projectId">
            <Editor />
          </Route>
        </Switch>
      </AuthHelper>
    </div>
  );
}

export default App;
