import { iconPause, iconPlay } from "assets/icons";
import Button from "components/Button";
import Typography from "components/Typography";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { formatSeconds } from "utils";

const Wrapper = styled.div<{ controls?: boolean }>`
  width: 100%;
  height: auto;
  position: relative;
  display: ${({ controls }) => (controls ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  user-select: none;
`;

const Progress = styled.div`
  flex: 1;
  width: 100%;
  height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &::before {
    z-index: 0;
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.border};
  }

  & > div {
    z-index: 1;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: width 0.25s ease-in-out;
    pointer-events: none;
  }
`;

type AudioProps = {
  src?: string;
  controls?: boolean;
  autoPlay?: boolean;
  startTime?: number;
  duration?: number;
};

const AudioPlayer = forwardRef<HTMLAudioElement, AudioProps>((props, ref) => {
  const { src, controls = true, autoPlay = false, startTime, duration } = props;
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  useImperativeHandle(ref, () => audioRef.current as HTMLAudioElement);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const audio = audioRef.current;
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [originalDuration, setOriginalDuration] = useState<number>();
  const [currentTime, setCurrentTime] = useState<number>();
  const isTrimmed = useMemo(() => {
    return typeof startTime === "number" && typeof duration === "number";
  }, [duration, startTime]);

  const handlePlayClick = () => {
    if (audio.paused) {
      audio.play();
      return;
    }
    audio.pause();
  };

  const handleLoadedMetaData = useCallback(() => {
    setOriginalDuration(audio.duration);
    if (autoPlay) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [audio, autoPlay]);

  const handleTimeUpdate = useCallback(() => {
    if (isTrimmed) {
      if (
        audio.currentTime > startTime! + duration! ||
        audio.currentTime < startTime!
      ) {
        audio.currentTime = startTime!;
        audio.pause();
      }
    }
    setCurrentTime(audio.currentTime - (startTime || 0));
  }, [audio, duration, isTrimmed, startTime]);

  const handlePlayStateChange = useCallback((event: Event) => {
    setIsPlaying(!(event.target as HTMLAudioElement).paused);
  }, []);

  const handleProgressChange: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (event) => {
        if (event.buttons === 1) {
          const pos = event.nativeEvent.offsetX;
          const rate = pos / event.currentTarget.offsetWidth;
          const newTime = rate * (isTrimmed ? duration! : originalDuration!);
          audio.currentTime = newTime + (startTime || 0);
        }
      },
      [audio, duration, isTrimmed, originalDuration, startTime]
    );

  useEffect(() => {
    if (audio && src) {
      audio.src = src;
    }
  }, [audio, src]);

  // useEffect(() => {
  //   if (audio) {
  //     audio.currentTime = startTime || 0;
  //   }
  // }, [audio, startTime]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetaData);
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("play", handlePlayStateChange);
      audio.addEventListener("pause", handlePlayStateChange);
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
        audio.removeEventListener("loadedmetadata", handleLoadedMetaData);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("play", handlePlayStateChange);
        audio.removeEventListener("pause", handlePlayStateChange);
      }
    };
  }, [audio, handleLoadedMetaData, handlePlayStateChange, handleTimeUpdate]);

  return (
    <Wrapper controls={controls} onMouseUp={() => buttonRef.current?.focus()}>
      <Button
        ref={buttonRef}
        onClick={handlePlayClick}
        link
        style={{ padding: 0 }}
      >
        <img
          src={isPlaying ? iconPause : iconPlay}
          alt="play/pause"
          style={{ height: 18 }}
        />
      </Button>
      <Typography type="caption1">{formatSeconds(currentTime)}</Typography>
      <Progress
        onMouseMove={handleProgressChange}
        onMouseDown={handleProgressChange}
      >
        <div
          style={{
            width: `${
              ((currentTime || 0) /
                ((isTrimmed ? duration : originalDuration) || 100)) *
              100
            }%`,
          }}
        />
      </Progress>
      <Typography type="caption1">
        {formatSeconds(isTrimmed ? duration : originalDuration)}
      </Typography>
    </Wrapper>
  );
});
export default AudioPlayer;
