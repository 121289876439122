import styled, { useTheme } from "styled-components";
import { ColorPicker, useColor, toColor } from "react-color-palette";
import Input from "components/Input";
import Typography from "components/Typography";
import Button from "components/Button";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding-bottom: 72px;
`;

const ColorChip = styled.button<{ color: string }>`
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;
  background: transparent;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  gap: 8px;
`;

const Footer = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 16px 32px;
  flex-direction: row-reverse;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const Preview = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  margin-bottom: 8px;
  & > div {
    width: auto;
    height: auto;
    position: relative;
    text-align: center;
    flex: 1;
  }
`;

type ColorPanelProps = {
  defaultColor?: string;
  currentColor?: string;
  onColorChange?(newColor?: string, prevColor?: string): void;
};

const ColorPanel = (props: ColorPanelProps) => {
  const {
    defaultColor: initialDefaultColor,
    currentColor: initialCurrentColor,
    onColorChange = () => {},
  } = props;
  const theme = useTheme();
  const [defaultColor] = useColor("hex", initialDefaultColor || "#ffffff");
  const [currentColor, setCurrentColor] = useColor(
    "hex",
    initialCurrentColor || "#ffffff"
  );
  return (
    <Wrapper>
      {!initialDefaultColor && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            type="body2"
            style={{ color: theme.colors.text.secondary }}
          >
            색상을 변경할 수 없는 템플릿입니다.
          </Typography>
        </div>
      )}
      {initialDefaultColor && (
        <Container>
          <Row>
            <div>
              <Typography type="body2" bold>
                색상
              </Typography>
            </div>
            <div>
              <Button
                link
                style={{ fontWeight: 400 }}
                onClick={() => setCurrentColor(defaultColor)}
              >
                <Typography type="body2" bold>
                  기존 색상으로
                </Typography>
              </Button>
            </div>
          </Row>
          <Preview>
            <div>
              <Typography type="body2" bold style={{ marginBottom: 18 }}>
                기존 색상
              </Typography>
              <br />
              <ColorChip
                color={defaultColor.hex}
                onClick={() => setCurrentColor(defaultColor)}
                style={{ marginBottom: 8 }}
              />
              <br />
              <Typography
                type="body2"
                style={{ color: theme.colors.text.secondary }}
              >
                {defaultColor?.hex.toUpperCase()}
              </Typography>
            </div>
            <div style={{ flex: "unset" }}>▶</div>
            <div>
              <Typography type="body2" bold style={{ marginBottom: 18 }}>
                변경 색상
              </Typography>
              <br />
              <ColorChip
                color={currentColor.hex}
                onClick={() => setCurrentColor(currentColor)}
                style={{ marginBottom: 8 }}
              />
              <br />
              <Typography
                type="body2"
                style={{ color: theme.colors.text.secondary }}
              >
                {currentColor?.hex.toUpperCase()}
              </Typography>
            </div>
          </Preview>
          <Row>
            <div>
              <Typography type="body2" bold>
                색상 선택하기
              </Typography>
            </div>
          </Row>
          <Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ColorPicker
              color={currentColor}
              onChange={setCurrentColor}
              width={300}
              height={140}
              hideHSV
              hideRGB
            />
          </Row>
          <div style={{ display: "none" }}>
            <Row>
              <div>
                <ColorChip color={currentColor.hex} />
              </div>
              <div>
                <Input
                  inputSize="small"
                  value={currentColor.hex}
                  onChange={(event) => {
                    const { value } = event.target;
                    const color = toColor("hex", value);
                    if (color.hex === value) {
                      setCurrentColor(toColor("hex", value));
                    }
                  }}
                  style={{ width: 100 }}
                />
              </div>
              <div>
                <Button link size="small" style={{ fontWeight: 400 }}>
                  즐겨찾는 색상 추가
                </Button>
              </div>
            </Row>
          </div>
          <Footer>
            <Button
              onClick={() =>
                onColorChange &&
                onColorChange(currentColor.hex, initialCurrentColor)
              }
            >
              변경하기
            </Button>
          </Footer>
        </Container>
      )}
    </Wrapper>
  );
};

export default ColorPanel;
