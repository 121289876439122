import Typography from "components/Typography";
import styled, { css } from "styled-components";

type Position = "top" | "right" | "bottom" | "left";

const Message = styled.div<{ position?: Position }>`
  position: absolute;
  z-index: 9999;
  white-space: nowrap;
  pointer-events: none;
  padding: 14px 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.text.secondary};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${({ position }) => {
    switch (position) {
      case "right":
        return css`
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          margin-left: 4px;
        `;
      case "bottom":
        return css`
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 4px;
        `;
      case "left":
        return css`
          top: 50%;
          right: 100%;
          transform: translateY(-50%);
          margin-right: 4px;
        `;
      case "top":
      default:
        return css`
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: 4px;
        `;
    }
  }}
`;

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  & > ${Message} {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover > ${Message} {
    opacity: 1;
  }
`;

const Tooltip = ({
  title,
  children,
  position = "top",
}: {
  title?: string | JSX.Element;
  position?: Position;
  children: JSX.Element;
}) => {
  return (
    <Wrapper>
      {children}
      {title && (
        <Message position={position}>
          <Typography type="caption1">
            {typeof title === "string"
              ? title.split("\n").map((s) => <p>{s}</p>)
              : title}
          </Typography>
        </Message>
      )}
    </Wrapper>
  );
};

export default Tooltip;
