import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";

const globalStyles = createGlobalStyle`
  ${reset};
  * {
    &, &::after, &::before {
      box-sizing: border-box;
    }
    outline-style: none;
    box-shadow: none;
  }
  html, body {
    ${({ theme }) =>
      theme.breakpoints.xxxLarge(css`
        font-size: 16px;
      `)}
    ${({ theme }) =>
      theme.breakpoints.small(css`
        font-size: 12px;
      `)}

    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  svg {
    display: inline-block;
    vertical-align: top;
  }

  .drawer-transition-top-enter {
    transform: translateY(-100%);
  }
  .drawer-transition-top-enter-active {
    transform: translateY(0%);
    transition: transform 0.3s ease-in-out;
  }
  .drawer-transition-top-exit {
    transform: translateY(0%);
  }
  .drawer-transition-top-exit-active {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .drawer-transition-right-enter {
    transform: translateX(100%);
  }
  .drawer-transition-right-enter-active {
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
  }
  .drawer-transition-right-exit {
    transform: translateX(0%);
  }
  .drawer-transition-right-exit-active {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .drawer-transition-bottom-enter {
    transform: translateY(100%);
  }
  .drawer-transition-bottom-enter-active {
    transform: translateY(0%);
    transition: transform 0.3s ease-in-out;
  }
  .drawer-transition-bottom-exit {
    transform: translateY(0%);
  }
  .drawer-transition-bottom-exit-active {
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .drawer-transition-left-enter {
    transform: translateX(-100%);
  }
  .drawer-transition-left-enter-active {
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
  }
  .drawer-transition-left-exit {
    transform: translateX(0%);
  }
  .drawer-transition-left-exit-active {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
`;

export default globalStyles;
