import { createImage } from "./rotateImage";

const resizeImage = async (img: string | HTMLImageElement, maxWidth = 1920) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const imgToResize = typeof img === "string" ? await createImage(img) : img;

  const originalWidth: number = imgToResize.width;
  const originalHeight: number = imgToResize.height;

  let canvasWidth = originalWidth;
  let canvasHeight = originalHeight;

  if (originalWidth > originalHeight) {
    if (originalWidth > maxWidth) {
      canvasWidth = maxWidth;
      canvasHeight = (originalHeight / originalWidth) * maxWidth;
    }
  } else {
    canvasWidth = (originalWidth / originalHeight) * maxWidth;
    canvasHeight = maxWidth;
  }

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context?.drawImage(imgToResize, 0, 0, canvasWidth, canvasHeight);

  return canvas.toDataURL();
};

export default resizeImage;
